import React from 'react';

import { SmileIcon } from '@src/components/utils/icomoon';

import { ITaskIconOption } from '../types';

import styles from './styles.module.scss';

interface ITaskAssigneeLabelItemProps {
  isBorder: boolean;
  toggleOpen: () => void;
  withBorder: string;
  value: ITaskIconOption;
}

const TaskAssigneeLabelItem = ({ isBorder, toggleOpen, withBorder, value }:ITaskAssigneeLabelItemProps) => {
  return (
    <div
      className={ isBorder ? withBorder : styles['profile-card'] }
      role="button"
      tabIndex={ 0 }
      onClick={ toggleOpen }
    >
      {value?.icon?.props?.vendor?.imageUrl
        ? <img alt="Judith Rodriguez" className={ styles.avatar } src={ value?.icon?.props?.vendor?.imageUrl } />
        : <SmileIcon inColor="orange-500" />}
      <div className={ styles.info }>
        <div className={ styles.name }>{value?.label}</div>
        <div className={ styles.title }>{value?.helper}</div>
      </div>
    </div>
  );
};

export default TaskAssigneeLabelItem;
