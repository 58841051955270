import React, { useCallback, useEffect, useState } from 'react';

import classNames from 'classnames';
import { GroupBase } from 'react-select';
import { LoadOptions } from 'react-select-async-paginate';

import {
  TIconOption,
} from '@src/components/ui_v2/inputs';

import TaskAssigneeLabelItem from './task_assignee_label_item';
import { AsyncPaginateReactSelectInput } from './task_react_select_input';
import { UserIconSelectOption } from './user_icon_select_option';
import { ITaskIconOption } from '../types';

import styles from './styles.module.scss';

interface ITaskAssigneeSelectProps {
  handleSource: LoadOptions<TIconOption, GroupBase<TIconOption>, { page: number }>;
  isLoading:boolean;
  toggleElement?:string | React.ReactNode;
  selectedItem?:ITaskIconOption;
  isBorder?: boolean;
  className?:string;
  onChange?:(newValue: ITaskIconOption | TIconOption | null | undefined) => void;
}

const Menu: React.FC<React.HTMLAttributes<HTMLDivElement>> = (props) => {
  return (
    <div
      { ...props }
      className={ styles['dropdown-container'] }
    />
  );
};

const Blanket: React.FC<React.HTMLAttributes<HTMLDivElement>> = (props) => (
  <div
    { ...props }
    className={ styles.blanket }
  />
);

const Dropdown: React.FC<{
  children: React.ReactNode;
  isOpen: boolean;
  target: React.ReactNode;
  onClose: () => void;
}> = ({ children, isOpen, target, onClose }) => (
  <div className={ styles['dropdown-wrapper'] }>
    {target}
    {isOpen && (
    <>
      <Menu>{children}</Menu>
      <Blanket onClick={ onClose } />
    </>
    )}
  </div>
);

export const TaskAssigneeSelect: React.FC<ITaskAssigneeSelectProps> = ({ handleSource, isBorder,
  selectedItem, isLoading, toggleElement, className = '', onChange }
  :ITaskAssigneeSelectProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [value, setValue] = useState<ITaskIconOption | TIconOption | undefined>(undefined);

  useEffect(() => {
    if (selectedItem?.value) {
      setValue(selectedItem);
    }
  }, [selectedItem]);

  const toggleOpen = useCallback(() => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  }, []);

  const onSelectChange = useCallback((newValue: ITaskIconOption | TIconOption | null) => {
    toggleOpen();
    setValue(newValue || undefined);
    if (onChange) onChange(newValue || undefined);
  }, [onChange, toggleOpen]);
  const withBorder = classNames(styles['profile-card'], styles['with-border'], className);
  return (
    <Dropdown
      isOpen={ isOpen }
      target={ (
        toggleElement ? (
          <div role="button" tabIndex={ 0 } onClick={ toggleOpen }>
            {toggleElement}
          </div>
        ) : (
          <TaskAssigneeLabelItem
            isBorder={ isBorder ?? false }
            toggleOpen={ toggleOpen }
            value={ value as ITaskIconOption }
            withBorder={ withBorder }
          />
        )
      ) }
      onClose={ toggleOpen }
    >
      <AsyncPaginateReactSelectInput
        closeMenuOnSelect
        isSearchable
        menuIsOpen
        additional={ {
          page: 1,
        } }
        debounceTimeout={ 300 }
        formatOptionLabel={ (data) => {
          return UserIconSelectOption(
            { label:    data?.label ?? '',
              value:    data?.value ?? '',
              subTitle: data.helper,
              icon:     data?.icon },
            { context: 'menu' },
          );
        } }
        isLoading={ isLoading }
        loadOptions={ handleSource }
        minMenuHeight={ 100 }
        onChange={ onSelectChange }
      />
    </Dropdown>
  );
};
