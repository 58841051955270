import React from 'react';

import Skeleton from 'react-loading-skeleton';

import { IReportServiceQBOLineItemDetails } from '@src/types/report_service/report_item_value_account';

import Table from '@src/components/ui_v2/table';

import Row from './row';

interface IThirdDrillDownBodyProps {
    itemId?: string,
    lineItemValues: IReportServiceQBOLineItemDetails[],
    columnNamesAndWidths: any;
}

const ThirdDrillDownBody = ({ itemId, lineItemValues, columnNamesAndWidths }: IThirdDrillDownBodyProps): JSX.Element => {
  const loadingSkeletonRows = [];

  for (let i = 0; i < 5; i += 1) {
    loadingSkeletonRows.push(
      <Table.Row key={ `${itemId}-row${i}` }>
        <Table.TextCell hideValueTooltip className="text-right" widthPercent={ columnNamesAndWidths?.date }>
          <Skeleton
            className="third-drill-down-table"
          />
        </Table.TextCell>
        <Table.TextCell hideValueTooltip className="text-right" widthPercent={ columnNamesAndWidths?.transaction_type }>
          <Skeleton
            className="third-drill-down-table"
          />
        </Table.TextCell>
        <Table.TextCell className="text-right" widthPercent={ columnNamesAndWidths?.num }>
          <Skeleton
            className="third-drill-down-table"
          />
        </Table.TextCell>
        <Table.TextCell className="text-left" widthPercent={ columnNamesAndWidths?.name }>
          <Skeleton
            className="third-drill-down-table"
          />
        </Table.TextCell>
        <Table.TextCell hideValueTooltip className="text-right" widthPercent={ columnNamesAndWidths?.amount }>
          <Skeleton
            className="third-drill-down-table"
          />
        </Table.TextCell>
        <Table.TextCell hideValueTooltip className="text-left" widthPercent={ columnNamesAndWidths?.memo }>
          <Skeleton
            className="third-drill-down-table"
          />
        </Table.TextCell>
        <Table.TextCell hideValueTooltip className="text-left" widthPercent={ columnNamesAndWidths?.split }>
          <Skeleton
            className="third-drill-down-table"
          />
        </Table.TextCell>
      </Table.Row>,
    );
  }
  if ((lineItemValues?.length === 0 || lineItemValues?.length === undefined)) {
    return (
      <Table.Body>
        {loadingSkeletonRows}
      </Table.Body>
    );
  }
  return (
    <Table.Body>
      {lineItemValues.map((value: any) => (
        <Row key={ value.id } columnNamesAndWidths={ columnNamesAndWidths } lineItemValue={ value } />
      ))}
    </Table.Body>
  );
};

export default ThirdDrillDownBody;
