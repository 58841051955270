import React, { useCallback, useState } from 'react';

import { useDropzone } from 'react-dropzone';
import { useNavigate } from 'react-router-dom';

import toastr from '@lib/toastr';
import { useUploadBankStatementAttachment } from '@src/hooks/bank_statements';
import { useUploadBalanceSheetAttachment } from '@src/hooks/queries/balance_sheet_statements';
import { useReviewDocumentRequest } from '@src/hooks/queries/document_requests';
import { useUploadMailroomDocument, useDeleteDocument } from '@src/hooks/queries/documents';
import { IBankStatementWithDocytId, IBalanceSheetStatementWithDocytId, useDocumentTableItems }
  from '@src/hooks/use_document_table_items';
import { downloadDocument } from '@src/requests/documents';
import { IBalanceSheetStatement } from '@src/types/balance_sheet_statements';
import { IBankStatement } from '@src/types/bank_statements';
import { TID } from '@src/types/common';
import { IDocumentModel } from '@src/types/document_model';
import { IDocument } from '@src/types/documents';

import { useConfirmDeleteModal } from '@src/components/common/confirm_delete';

import DocumentDropZone from './document_drop_zone';
import DocumentTable from './document_table';
import NoStatementCheckbox from './no_statement_checkbox';

import styles from './document_list.module.scss';

interface DocumentUploaderProps {
  model: IDocumentModel;
  isUploading: boolean;
  setIsUploading: (value: boolean) => void;
  documents: IDocument[];
  setDocuments?: (documents: IDocument[]) => void;
  noStatementAvailable: boolean;
  setNoStatementAvailable: (value: boolean) => void;
  onDocumentsUpdate?: (docs: IDocument[]) => void;
  bankStatement: IBankStatementWithDocytId;
  balanceSheetStatements: IBalanceSheetStatementWithDocytId[];
  isReviewed?: boolean;
}

export type DocumentType = 'bank_statement' | 'balance_sheet' | 'document';

const DocumentUploader: React.FC<DocumentUploaderProps> = ({
  model,
  isUploading,
  setIsUploading,
  documents,
  bankStatement,
  balanceSheetStatements,
  noStatementAvailable,
  setNoStatementAvailable,
  onDocumentsUpdate,
  isReviewed = false,
}) => {
  const [selectedDocId, setSelectedDocId] = useState<TID>(0);
  const uploadAttachment = useUploadBankStatementAttachment();
  const uploadBalanceSheet = useUploadBalanceSheetAttachment();
  const navigate = useNavigate();
  const reviewRequest = useReviewDocumentRequest();

  const isMailroomRequest = model.isMailroomRequest();
  const isBankStatementRequest = model.isBankStatementRequest();
  const isBalanceSheetRequest = model.isBalanceSheetStatementRequest();

  const deleteDocument = useDeleteDocument();

  const deleteModal = useConfirmDeleteModal({
    onDone: async (confirmed: boolean) => {
      if (confirmed && selectedDocId) {
        try {
          await deleteDocument.mutateAsync({ documentId: selectedDocId });
          const updatedDocs = documents.filter((doc) => doc.id !== selectedDocId);
          onDocumentsUpdate?.(updatedDocs);
          toastr.success('Document deleted successfully', 'Success');
        } catch {
          toastr.error('Failed to delete document', 'Error');
        }
      }
    },
  });

  const validateBankStatementFileFormat = useCallback((file: File): boolean => {
    const allowedSize = window.configData.allowed_file_upload_size;
    const fileExtension = file.name.split('.').pop()?.toLowerCase();

    if (file.size > allowedSize) {
      toastr.error(`File size should be less than ${allowedSize}`, 'Something went wrong');
      return false;
    }

    if (!fileExtension || !window.configData.allowed_file_upload_format.includes(fileExtension)) {
      toastr.error(
        'File you uploaded is not supported. You can only upload in one of these formats: pdf',
        'Something went wrong',
      );
      return false;
    }

    return true;
  }, []);

  const documentTableItems = useDocumentTableItems({
    documents,
    bankStatement,
    balanceSheetStatements,
    isMailroomRequest:      model.isMailroomRequest(),
    isBankStatementRequest: model.isBankStatementRequest(),
    isBalanceSheetRequest:  model.isBalanceSheetStatementRequest(),
    isJustUploaded:         false,
    isReviewed,
  });

  const renderDocumentTable = useCallback((isJustUploaded: boolean = false) => {
    if (!documentTableItems.length) {
      // Only show "No document available" if:
      // 1. Request is reviewed OR
      // 2. No Statement Available checkbox is checked
      if (isReviewed || noStatementAvailable) {
        return (
          <div className={ styles['documents-table'] }>
            <div className={ styles['no-documents-message'] }>
              No document available
            </div>
          </div>
        );
      }
      return null;
    }

    const handleDocumentClick = async (e: React.MouseEvent, docId: number, type: string) => {
      e.preventDefault();
      e.stopPropagation();

      if (type === 'bank_statement') {
        navigate(`/businesses/${model.get('business_id')}/documents/${docId}`);
        return;
      }

      try {
        toastr.success('Downloading document...', 'Download in progress');
        await downloadDocument({
          documentId: docId,
          type:       'original',
        });
        toastr.success('Document downloaded successfully', 'Success');
      } catch {
        toastr.error('Document PDF not ready', 'Warning');
      }
    };

    return (
      <>
        <DocumentTable
          balanceSheetStatements={ balanceSheetStatements }
          bankStatement={ bankStatement }
          businessId={ model.get('business_id') as TID }
          documents={ documents }
          isBalanceSheetRequest={ isBalanceSheetRequest }
          isBankStatementRequest={ isBankStatementRequest }
          isJustUploaded={ isJustUploaded }
          isMailroomRequest={ isMailroomRequest }
          isReviewed={ isReviewed }
          onDeleteClick={ (docId) => {
            setSelectedDocId(docId);
            deleteModal.open();
          } }
          onDocumentClick={ handleDocumentClick }
        />
        <deleteModal.Component
          confirmStyle="primary"
          confirmTitle="Delete"
          text="Are you sure you want to delete this document?"
          title="Delete Document"
          { ...deleteModal.props }
        />
      </>
    );
  }, [
    documents,
    deleteModal,
    isReviewed,
    isMailroomRequest,
    isBalanceSheetRequest,
    isBankStatementRequest,
    bankStatement,
    balanceSheetStatements,
    model,
    navigate,
    noStatementAvailable,
    documentTableItems,
  ]);

  const uploadBankStatement = useCallback(async (files: File[]): Promise<IBankStatement[]> => {
    setIsUploading(true);
    try {
      const uploadPromises = files.map(async (file) => {
        if (!validateBankStatementFileFormat(file)) {
          return null;
        }

        try {
          const response = await uploadAttachment.mutateAsync({
            businessId:      model.get('business_id') as number,
            bankStatementId: bankStatement.id,
            file,
          });

          if (response.bankStatement) {
            const newDocument: IDocument = {
              id:                        response.bankStatement.id,
              name:                      response.bankStatement.name || '',
              docytId:                   response.bankStatement.docytId || '',
              chatId:                    0,
              consumerId:                0,
              current:                   true,
              businessDocuments:         [],
              businessNames:             [],
              businesses:                [],
              createdAt:                 new Date().toISOString(),
              documentOwners:            [],
              source:                    '',
              state:                     'uploaded',
              storageSize:               0,
              unencryptedDocumentFields: [],
              updatedAt:                 new Date().toISOString(),
              uploaderEmail:             '',
              uploaderName:              '',
              dueOff:                    false,
              expiryOff:                 false,
              haveAccess:                true,
              isFrozen:                  false,
              pages:                     [],
              sharers:                   [],
              lastModifiedAt:            new Date().toISOString(),
              computed_final_filename:   null,
              final_file_key:            null,
              original_file_key:         null,
              original_file_name:        null,
            };

            // Update documents list
            onDocumentsUpdate?.([...(documents || []), newDocument]);

            // Mark request as reviewed
            await reviewRequest.mutateAsync({ documentRequestId: model.id });

            // Trigger events to update UI
            window.Docyt.vent.trigger('document:request:reviewed', model.id);
            window.Docyt.vent.trigger('transactions:request:done');

            // Render document table with isJustUploaded flag
            renderDocumentTable(true);

            return response.bankStatement;
          }
          return null;
        } catch {
          toastr.error('Failed to upload file', 'Error');
          return null;
        }
      });

      const results = await Promise.all(uploadPromises);
      const successfulUploads = results.filter((r): r is IBankStatement => r !== null);

      if (successfulUploads.length > 0) {
        toastr.success('Files uploaded successfully', 'Success');
      }

      return successfulUploads;
    } catch {
      toastr.error('Upload failed', 'Error');
      return [];
    } finally {
      setIsUploading(false);
    }
  }, [model, bankStatement?.id, documents, onDocumentsUpdate, setIsUploading,
    uploadAttachment, validateBankStatementFileFormat, reviewRequest, renderDocumentTable]);

  const uploadBalanceSheetStatement = useCallback(async (files: File[]): Promise<IBalanceSheetStatement[]> => {
    setIsUploading(true);
    try {
      const uploadPromises = files.map(async (file) => {
        if (!validateBankStatementFileFormat(file)) {
          return null;
        }
        try {
          const response = await uploadBalanceSheet.mutateAsync({
            businessId:              model.get('business_id') as number,
            balanceSheetStatementId: balanceSheetStatements[0]?.id,
            file,
          });

          if (response.balanceSheetStatement) {
            const newDocument: IDocument = {
              id:                        response.balanceSheetStatement.id,
              name:                      response.balanceSheetStatement.name || '',
              docytId:                   response.balanceSheetStatement.docytId || '',
              chatId:                    0,
              consumerId:                0,
              current:                   true,
              businessDocuments:         [],
              businessNames:             [],
              businesses:                [],
              createdAt:                 new Date().toISOString(),
              documentOwners:            [],
              source:                    '',
              state:                     'uploaded',
              storageSize:               0,
              unencryptedDocumentFields: [],
              updatedAt:                 new Date().toISOString(),
              uploaderEmail:             '',
              uploaderName:              '',
              dueOff:                    false,
              expiryOff:                 false,
              haveAccess:                true,
              isFrozen:                  false,
              pages:                     [],
              sharers:                   [],
              lastModifiedAt:            new Date().toISOString(),
              computed_final_filename:   null,
              final_file_key:            null,
              original_file_key:         null,
              original_file_name:        null,
            };

            onDocumentsUpdate?.([...(documents || []), newDocument]);
            await reviewRequest.mutateAsync({ documentRequestId: model.id });

            window.Docyt.vent.trigger('document:request:reviewed', model.id);
            window.Docyt.vent.trigger('transactions:request:done');

            renderDocumentTable(true);

            return response.balanceSheetStatement;
          }
          return null;
        } catch {
          toastr.error('Failed to upload file', 'Error');
          return null;
        }
      });

      const results = await Promise.all(uploadPromises);
      const successfulUploads = results.filter((r): r is IBalanceSheetStatement => r !== null);

      if (successfulUploads.length > 0) {
        toastr.success('Files uploaded successfully', 'Success');
      }

      return successfulUploads;
    } catch {
      toastr.error('Upload failed', 'Error');
      return [];
    } finally {
      setIsUploading(false);
    }
  }, [model, balanceSheetStatements, documents, onDocumentsUpdate, setIsUploading,
    uploadBalanceSheet, validateBankStatementFileFormat, reviewRequest, renderDocumentTable]);

  const uploadMailroomDocument = useUploadMailroomDocument();

  const uploadMailroomDocuments = useCallback(async (files: File[]): Promise<IDocument[]> => {
    try {
      const uploadPromises = files.map((file) => uploadMailroomDocument.mutateAsync({
        file,
        businessId:        model.get('business_id') as TID,
        documentRequestId: model.id,
      }));

      const uploadedDocuments = await Promise.all(uploadPromises);

      uploadedDocuments.forEach((uploadedDocument) => {
        if (onDocumentsUpdate) {
          const updatedDocs = [...(documents || []), uploadedDocument];
          onDocumentsUpdate(updatedDocs);
        }
        // For mailroom requests, we only trigger document:uploaded
        // Do NOT trigger any review-related events as that happens manually later
        window.Docyt.vent.trigger('document:uploaded', model.id);
      });

      if (uploadedDocuments.length > 0) {
        toastr.success('Files uploaded successfully', 'Success');
      }

      return uploadedDocuments;
    } catch {
      toastr.error('Failed to upload documents', 'Error');
      return [];
    }
  }, [model, documents, onDocumentsUpdate, uploadMailroomDocument]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({

    onDrop: async (acceptedFiles) => {
      if (isUploading) return;

      try {
        setIsUploading(true);

        if (isMailroomRequest) {
          // Handle mailroom documents separately
          await uploadMailroomDocuments(acceptedFiles);
        } else if (isBankStatementRequest) {
          // Handle bank statement uploads
          if (acceptedFiles.length > 1) {
            toastr.error('Only one file can be uploaded at a time', 'Error');
            return;
          }

          if (documents.length > 0) {
            return;
          }

          const uploadedDocs = await uploadBankStatement([acceptedFiles[0]]);
          if (uploadedDocs.length > 0) {
            window.Docyt.vent.trigger('transactions:request:done');
          }
        } else if (isBalanceSheetRequest) {
          if (acceptedFiles.length > 1) {
            toastr.error('Only one file can be uploaded at a time', 'Error');
            return;
          }

          if (documents.length > 0) {
            return;
          }

          const uploadedDocs = await uploadBalanceSheetStatement([acceptedFiles[0]]);
          if (uploadedDocs.length > 0) {
            window.Docyt.vent.trigger('transactions:request:done');
          }
        }
      } catch {
        toastr.error('Upload failed', 'Error');
      } finally {
        setIsUploading(false);
      }
    },
    multiple: isMailroomRequest,
    accept:   '.pdf,application/pdf',
  });

  return (
    <div>
      {renderDocumentTable() }
      {!isReviewed && (
        <>
          <DocumentDropZone
            getInputProps={ getInputProps }
            getRootProps={ getRootProps }
            isDragActive={ isDragActive }
            isMailroomRequest={ isMailroomRequest }
            isReviewed={ isReviewed }
            isUploading={ isUploading }
          />
          <NoStatementCheckbox
            documentsLength={ documents.length }
            isMailroomRequest={ isMailroomRequest }
            noStatementAvailable={ noStatementAvailable }
            setNoStatementAvailable={ setNoStatementAvailable }
          />
        </>
      )}
    </div>
  );
};

export default DocumentUploader;
