import React, { useCallback } from 'react';

import { IReportServiceQBOLineItemDetails } from '@src/types/report_service/report_item_value_account';

import Table from '@src/components/ui_v2/table';

import { IColumnNamesAndWidths } from './types';

interface ThirdDrillDownHeaderProps {
    lineItemValues: IReportServiceQBOLineItemDetails[],
    handleCollapseClick: () => void,
    columnNamesAndWidths: IColumnNamesAndWidths,
    showThirdDrillDownModal: boolean,
    setShowThirdDrillDownModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const ThirdDrillDownHeader = ({ lineItemValues, handleCollapseClick, columnNamesAndWidths, showThirdDrillDownModal, setShowThirdDrillDownModal }: ThirdDrillDownHeaderProps): JSX.Element => {
  const handleExpandClick = useCallback(() => {
    setShowThirdDrillDownModal(!showThirdDrillDownModal);
  }, [setShowThirdDrillDownModal, showThirdDrillDownModal]);

  const expandAndCollapseContent = (
    <>
      <span aria-hidden className="m-r-10 pull-right" role="button" onClick={ handleCollapseClick }><i className="glyphicon glyphicon-remove-circle font-14" /></span>
      { lineItemValues.length > 0 && (<span aria-hidden className="m-l-20 m-r-10 pull-right" role="button" onClick={ handleExpandClick }><i className="glyphicon glyphicon-resize-full font-14" /></span>)}
    </>
  );

  return (
    <Table.Head>
      <Table.Row>
        <Table.TextCell hideValueTooltip className="text-right" widthPercent={ columnNamesAndWidths.date }>
          Date
        </Table.TextCell>
        <Table.TextCell hideValueTooltip className="text-right" widthPercent={ columnNamesAndWidths.transaction_type }>
          Transaction Type
        </Table.TextCell>
        <Table.TextCell hideValueTooltip className="text-right" widthPercent={ columnNamesAndWidths.num }>
          NUM
        </Table.TextCell>
        <Table.TextCell hideValueTooltip className="text-left" widthPercent={ columnNamesAndWidths.name }>
          Name
        </Table.TextCell>
        <Table.TextCell hideValueTooltip className="text-right" widthPercent={ columnNamesAndWidths.amount }>
          Amount
        </Table.TextCell>
        <Table.TextCell hideValueTooltip className="text-left" widthPercent={ columnNamesAndWidths.memo }>
          Memo
        </Table.TextCell>
        <Table.TextCell hideValueTooltip widthPercent={ columnNamesAndWidths.split }>
          <span className="text-left">Split</span>
          { !showThirdDrillDownModal && expandAndCollapseContent }
        </Table.TextCell>
      </Table.Row>
    </Table.Head>
  );
};

export default ThirdDrillDownHeader;
