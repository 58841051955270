import { TID, TUUID } from '@src/types/common';
import { TFilterData } from '@src/types/filter';
import { ITaskLogItem, ITaskServiceDocument, ITaskServiceDocumentFilterQuery,
  TTasksDocumentsSortColumn } from '@src/types/task_service_documents';
import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

import { ILogEntry } from '@src/components/task_management/types';

import { apiDelete, apiGet, apiPatch, apiPost } from './helpers';

export interface IGetTaskServiceDocumentsResponse {
  meta: {
    totalCount: number,
    totalAmount?: number,
    duplicatedCount: number,
  },
  collection: ITaskServiceDocument[],
}
export interface IGetTaskLogsDocumentsResponse {
  meta: {
    totalCount: number,
    totalAmount?: number,
    duplicatedCount: number,
  },
  collection: ITaskLogItem[],
}
export interface IGetTaskServiceDocumentsParams {
  page?: number,
  orderColumn?: TTasksDocumentsSortColumn,
  queryType?: string,
  ps:number;
  filter?:ITaskServiceDocumentFilterQuery;
}
export interface ICreateTaskBody {
    title:string;
    businessId:TID;
    description:string;
    userName?:string;
    status?:string;
    userId?:TID;
}
export interface IDeleteTaskBody {
  taskId:string;
}
export interface IUpdateTaskProps {
  formData: ICreateTaskBody;
  taskId:string;
}
export interface IAssignTaskFormData {
  userId: string;
}
export interface IAssignTaskProps {
  formData: IAssignTaskFormData;
  taskId:string;
}
export interface Avatar {
  id: number;
  s3ObjectKey: string;
  state: string;
  avatarableId: number;
  avatarableType: string;
  avatarUrl: string;
}
export interface IUser {
  id: TID;
  avatar: Avatar;
  parsedFullname: string;
  permissionType:string; // Adjust if needed
  email: string;
  phone: string;
  title: string;
  isEmailVerified: boolean;
}
export interface ISearchTaskUsersParams{
    businessId: TID,
    search?: string,
    page?: number,
    perPage?: number,
    filter?: TFilterData,
}
interface ISearchTaskUsersResponse {
  meta: {
    totalCount: number,
  },
  collection: IUser[],
}
export interface IGetTaskLogsDocumentsParams extends IGetTaskServiceDocumentsParams{
  taskId?:string;
}
export const getTaskServiceDocuments = ({
  ...params
}: IGetTaskServiceDocumentsParams): Promise<IGetTaskServiceDocumentsResponse> => {
  const url = '/task/api/v1/task/all';
  return apiGet(
    url,
    {
      ...underscoreKeys(
        { ...params },
      ),
    },
  ).then((data) => {
    const cdata = <any>camelizeKeys(data);
    return {
      collection: cdata.response,
      meta:       {
        totalCount: cdata.totalCount,
      },
    } as IGetTaskServiceDocumentsResponse;
  });
};
export const getSearchTaskUsers = (
  params: ISearchTaskUsersParams,
): Promise<ISearchTaskUsersResponse> => {
  return apiGet(
    '/api/v1/business_users',
    underscoreKeys(params),
  ).then((data) => {
    const cdata = <any>camelizeKeys(data);
    return {
      ...cdata,
      collection: cdata.businessUsers,
    } as ISearchTaskUsersResponse;
  });
};
export const getTaskServiceDocumentLogsDocuments = ({
  ...params
}: IGetTaskLogsDocumentsParams): Promise<IGetTaskLogsDocumentsResponse> => {
  const url = `/task/api/v1/log/${params.taskId}`;
  return apiGet(
    url,
    {
      ...underscoreKeys(
        { ...params },
      ),
    },
  ).then((data) => {
    const cdata = <any>camelizeKeys(data);
    return {
      collection: cdata.response,
      meta:       {
        totalCount: cdata.totalElements,
      },
    } as IGetTaskLogsDocumentsResponse;
  });
};
interface ICreateTaskResponse {
  id: string;
  message: string
}
export const createTaskServiceDocument = (
  formData: ICreateTaskBody,
): Promise<ICreateTaskResponse> => {
  return apiPost(
    '/task/api/v1/task',
    underscoreKeys(formData),
  ).then((data) => {
    return camelizeKeys(data) as ICreateTaskResponse;
  });
};
export const deleteTaskServiceDocument = ({ taskId }:IDeleteTaskBody): Promise<ICreateTaskResponse> => {
  return apiDelete(
    `/task/api/v1/task/${taskId}`,
  ).then((data) => {
    return camelizeKeys(data) as ICreateTaskResponse;
  });
};
export const updateTaskServiceDocument = ({ formData, taskId }: IUpdateTaskProps): Promise<ICreateTaskResponse> => {
  return apiPatch(
    `/task/api/v1/task/${taskId}`,
    underscoreKeys(formData),
  ).then((data) => {
    return camelizeKeys(data) as ICreateTaskResponse;
  });
};
export const assignTaskServiceDocument = ({ formData, taskId }: IAssignTaskProps): Promise<ICreateTaskResponse> => {
  return apiPost(
    `/task/api/v1/task/${taskId}/assign`,
    underscoreKeys(formData),
  ).then((data) => {
    return camelizeKeys(data) as ICreateTaskResponse;
  });
};
export const getTaskServiceDocumentById = (taskId: TUUID): Promise<ITaskServiceDocument> => {
  return apiGet(
    `/task/api/v1/task/${taskId}`,
  ).then((result) => {
    return camelizeKeys(result) as ITaskServiceDocument;
  });
};
export const getTaskServiceDocumentLogsById = (taskId: TUUID): Promise<ILogEntry[]> => {
  return apiGet(
    `/task/api/v1/task/${taskId}`,
  ).then((result) => {
    return camelizeKeys(result) as ILogEntry[];
  });
};
