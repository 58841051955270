import React, { useState, useCallback, useEffect, useMemo } from 'react';

import { useForm } from 'react-hook-form';

import { useReviewDocumentRequest } from '@src/hooks/queries/document_requests';
import { IBalanceSheetStatement } from '@src/types/balance_sheet_statements';
import { IBankStatement } from '@src/types/bank_statements';
import { TID } from '@src/types/common';
import { IDocumentModel } from '@src/types/document_model';
import { IDocumentRequest } from '@src/types/document_requests';
import { IDocument } from '@src/types/documents';

import SideView from '@src/components/ui/side_view';
import Spinner from '@src/components/ui/spinner';
import Form from '@src/components/ui_v2/form';

import DocumentsSection from '../documents_section';
import NotesSection from '../notes_section';

import styles from '../document_list.module.scss';

interface DocumentConversationRightSideViewProps {
  businessId: TID;
  model: IDocumentModel;
  bankStatement: IBankStatement;
  documents: IDocument[];
  balanceSheetStatements: IBalanceSheetStatement[];
  onClose: () => void;
  isLast: boolean;
  documentRequests: IDocumentRequest[];
}

interface IConversationFormInput {
  documents: IDocument[];
  note?: string;
}

const DocumentConversationRightSideView: React.FC<DocumentConversationRightSideViewProps> = ({
  bankStatement,
  businessId,
  model,
  documents: initialDocuments,
  balanceSheetStatements: initialBalanceSheetStatements,
  onClose,
  isLast,
  documentRequests,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [isDocumentsExpanded, setIsDocumentsExpanded] = useState<boolean>(true);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const balanceSheetStatements = useMemo<IBalanceSheetStatement[]>(
    () => initialBalanceSheetStatements,
    [initialBalanceSheetStatements],
  );
  const [noStatementAvailable, setNoStatementAvailable] = useState<boolean>(false);
  const [note, setNote] = useState<string>('');
  const [isReviewed] = useState<boolean>(model.get('state') === 'reviewed');
  const [documents, setDocuments] = useState<IDocument[]>(initialDocuments);

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<IConversationFormInput>();

  const reviewDocumentRequest = useReviewDocumentRequest();

  // Move isReviewedState outside the callback
  const isReviewedState = useMemo(() => model.get('state') === 'reviewed', [model]);

  // Modify the updateLastStatus implementation
  const updateLastStatus = useCallback(() => {
    if (isReviewedState) {
      window.Docyt.vent.trigger('transactions:request:done');
    }
  }, [isReviewedState]);

  // Modify the effect to properly expose the method
  useEffect(() => {
    if (!window.Docyt) return;
    
    if (!window.Docyt.rightSideViewContent) {
      window.Docyt.rightSideViewContent = {};
    }

    window.Docyt.rightSideViewContent.updateLastStatus = updateLastStatus;

    return () => {
      if (window.Docyt?.rightSideViewContent) {
        window.Docyt.rightSideViewContent.updateLastStatus = undefined;
      }
    };
  }, [updateLastStatus]);

  const handleNoteChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNote(e.target.value);
  }, []);

  const toggleDocumentsSection = useCallback(() => {
    setIsDocumentsExpanded((prev) => !prev);
  }, []);

  const findNextDocumentRequest = useCallback(() => {
    // Filter documents based on current state
    const currentState = model.get('state');
    const filteredRequests = documentRequests?.filter((req) => {
      if (currentState === 'reviewed') {
        return req.state === 'reviewed';
      }
      return req.state === 'opened';
    });

    const currentIndex = filteredRequests?.findIndex((req) => req.id === model.id);

    if (currentIndex >= 0 && currentIndex < (filteredRequests?.length || 0) - 1) {
      const nextRequest = filteredRequests[currentIndex + 1];

      // Find the actual Backbone model from the collection view
      const nextRequestRow = document.querySelector(
        `.client-central-main-view .document-requests-region tr[data-request-id="${nextRequest.id}"]`,
      );
      if (nextRequestRow) {
        const detailColumn = nextRequestRow.querySelector('td.request-detail-column');
        if (detailColumn) {
          (detailColumn as HTMLElement).click();
          return null;
        }
      }

      return nextRequest;
    }

    return null;
  }, [documentRequests, model]);

  const onSubmit = useCallback(async (_data: IConversationFormInput) => {
    try {
      if (isLoading) return;
      setIsLoading(true);

      const nextRequest = findNextDocumentRequest();

      if (!isReviewed) {
        // Use the mutation hook instead of direct API call
        await reviewDocumentRequest.mutateAsync({
          documentRequestId: model.id,
        });

        window.Docyt.vent.trigger('document:request:reviewed', model.id);
      }

      if (nextRequest) {
        // First uncheck current item
        window.Docyt.vent.trigger('request:item:unselected');
        // Find and click the actual DOM element
        const nextRequestElement =
          document.querySelector(`tr[data-request-id="${nextRequest.id}"] td.request-detail-column`);
        if (nextRequestElement) {
          (nextRequestElement as HTMLElement).click();
        } else {
          // Fallback to event triggers if element not found
          window.Docyt.vent.trigger('request:item:clicked', nextRequest);
          window.Docyt.vent.trigger('active:request:item:changed', nextRequest);
        }
      }

      if (!nextRequest) {
        window.Docyt.vent.trigger('client:central:right:side:closed');
      }

      window.Docyt.vent.trigger('transactions:request:done');
    } catch {
      setErrorMessage('An error occurred while processing the request');
    } finally {
      setIsLoading(false);
    }
  }, [isReviewed, model.id, findNextDocumentRequest, isLoading, reviewDocumentRequest]);

  const getReviewButtonText = useCallback(() => {
    const isMailroomRequest = model.get('request_type') === window.Docyt.Common.Constants.SERVICE_NAMES.MAILROOM;

    // For reviewed items, only show "Review Next" for non-last items
    if (isReviewed) {
      return 'Review Next'; // Button visibility is controlled by shouldShowButton
    }

    // For unreviewed mailroom items
    if (isMailroomRequest) {
      return isLast ? 'Mark as Reviewed' : 'Mark as Reviewed - Review Next';
    }

    return undefined;
  }, [model, isReviewed, isLast]);

  const handleCloseClick = useCallback(() => {
    window.Docyt.vent.trigger('request:item:unselected');
    onClose();
  }, [onClose]);

  const documentModel = useMemo(() => ({
    id:             model.id,
    business_id:    businessId,
    bank_statement: bankStatement ? {
      ...bankStatement,
      docytId: bankStatement.docytId,
    } : null,
    balance_sheet_statements: balanceSheetStatements?.map((stmt) => ({
      ...stmt,
      docytId:      stmt.docytId,
      request_type: model.get('request_type'),
    })),
    isBankStatementRequest:         () => model.get('document_type') === 'bank-statement',
    isBalanceSheetStatementRequest: () => model.get('document_type') === 'balance-sheet-statement',
    isMailroomRequest:              () => model.get('document_type') === 'mailroom',
    get:                            (key: string) => {
      if (key === 'business_id') return businessId;
      if (key === 'document_type') {
        if (bankStatement?.statementFileUrl) return 'bank-statement';
        if (balanceSheetStatements?.[0]?.state !== 'requested') return 'balance-sheet-statement';
        return 'mailroom';
      }
      return model.get(key);
    },
  }), [model, businessId, bankStatement, balanceSheetStatements]);

  // Add a function to determine button visibility
  const shouldShowButton = useCallback(() => {
    const isMailroomRequest = model.get('request_type') === window.Docyt.Common.Constants.SERVICE_NAMES.MAILROOM;

    // For reviewed items
    if (isReviewed) {
      // Hide button for last item
      return !isLast;
    }

    // For unreviewed items
    return isMailroomRequest; // Only show for mailroom requests
  }, [model, isReviewed, isLast]);

  const handleDocumentsUpdate = useCallback((updatedDocs: IDocument[]) => {
    setDocuments(updatedDocs);
  }, []);

  return (
    <div>
      <SideView.Form
        isOpen
        isRoot
        doneTitle={ getReviewButtonText() }
        isSubmitDisabled={ isSubmitting }
        showButton={ shouldShowButton() }
        title="Conversations"
        onCancel={ handleCloseClick }
      >
        {({ formId }) => (
          <Form id={ formId } onSubmit={ handleSubmit(onSubmit) }>
            {isLoading && <Spinner />}
            {errorMessage && (
              <SideView.Error>
                <div className="error-message">
                  <span>{errorMessage}</span>
                </div>
              </SideView.Error>
            )}
            <div className={ styles.sections }>
              <DocumentsSection
                balanceSheetStatements={ balanceSheetStatements as IBalanceSheetStatement[] }
                bankStatement={ bankStatement }
                documents={ documents }
                isDocumentsExpanded={ isDocumentsExpanded }
                isReviewed={ isReviewed }
                isUploading={ isUploading }
                model={ documentModel }
                noStatementAvailable={ noStatementAvailable }
                setDocuments={ setDocuments }
                setIsUploading={ setIsUploading }
                setNoStatementAvailable={ setNoStatementAvailable }
                toggleDocumentsSection={ toggleDocumentsSection }
                onDocumentsUpdate={ handleDocumentsUpdate }
              />

              <hr className={ styles['section-divider'] } />

              <NotesSection
                isReviewed={ isReviewed }
                note={ note }
                onNoteChange={ handleNoteChange }
              />
            </div>
          </Form>
        )}
      </SideView.Form>
    </div>
  );
};

export default DocumentConversationRightSideView;
