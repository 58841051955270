import { useMutation, useQuery, useQueryClient } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import { assignTaskServiceDocument, createTaskServiceDocument, deleteTaskServiceDocument, getTaskServiceDocumentById,
  getTaskServiceDocumentLogsById, getTaskServiceDocumentLogsDocuments, getTaskServiceDocuments,
  IAssignTaskProps,
  ICreateTaskBody, IDeleteTaskBody, IGetTaskLogsDocumentsParams,
  IGetTaskLogsDocumentsResponse, IGetTaskServiceDocumentsParams,
  IGetTaskServiceDocumentsResponse,
  IUpdateTaskProps,
  updateTaskServiceDocument } from '@src/requests/task_service_documents';
import {
} from '@src/requests/transaction_service_documents';
import { TUUID } from '@src/types/common';
import { ITaskLogItem, ITaskServiceDocument } from '@src/types/task_service_documents';

import { ILogEntry } from '@src/components/task_management/types';

import {
  createUseGetInfiniteCollection,
} from './infinite_collection_queries';

const useGetTaskServiceDocuments = createUseGetInfiniteCollection<
  ITaskServiceDocument,
  IGetTaskServiceDocumentsParams,
  IGetTaskServiceDocumentsResponse
>({
  queryKey: QueryKey.taskServiceDocuments,
  request:  getTaskServiceDocuments,
});
const useGetTaskServiceLogsDocumentLogs = createUseGetInfiniteCollection<
 ITaskLogItem,
  IGetTaskLogsDocumentsParams,
  IGetTaskLogsDocumentsResponse
>({
  queryKey: QueryKey.taskLogsDocuments,
  request:  getTaskServiceDocumentLogsDocuments,
});
const useCreateTaskServiceDocument = () => {
  const queryClient = useQueryClient();
  return useMutation<
      {},
      Error,
      ICreateTaskBody
    >(createTaskServiceDocument, {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.taskServiceDocuments);
      },
    });
};
const useDeleteTaskServiceDocument = () => {
  const queryClient = useQueryClient();
  return useMutation<
      {},
      Error, IDeleteTaskBody>(deleteTaskServiceDocument, {
        onSuccess: () => {
          queryClient.invalidateQueries(QueryKey.taskLogsDocuments);
          queryClient.invalidateQueries(QueryKey.taskServiceDetailsDocument);
        },
      });
};
const useUpdateTaskServiceDocument = () => {
  const queryClient = useQueryClient();
  return useMutation<
      {},
      Error,
      IUpdateTaskProps
    >(updateTaskServiceDocument, {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.taskLogsDocuments);
        queryClient.invalidateQueries(QueryKey.taskServiceDetailsDocument);
      },
    });
};

const useAssignTaskServiceDocument = () => {
  const queryClient = useQueryClient();
  return useMutation<
      {},
      Error,
      IAssignTaskProps
    >(assignTaskServiceDocument, {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.taskServiceDocuments);
      },
    });
};

const useGetTaskServiceDocumentById = (taskId: TUUID) => {
  return useQuery<ITaskServiceDocument, Error>(
    [QueryKey.taskServiceDetailsDocument, taskId],
    () => getTaskServiceDocumentById(taskId),
  );
};
const useGetTaskServiceDocumentLogsById = (taskId: TUUID) => {
  return useQuery<ILogEntry[], Error>(
    [taskId],
    () => getTaskServiceDocumentLogsById(taskId),
  );
};
export {
  useGetTaskServiceDocuments,
  useCreateTaskServiceDocument,
  useGetTaskServiceDocumentById,
  useUpdateTaskServiceDocument,
  useDeleteTaskServiceDocument,
  useGetTaskServiceDocumentLogsById,
  useGetTaskServiceLogsDocumentLogs,
  useAssignTaskServiceDocument,
};
