import React, { useState } from 'react';

import { useGetLineItemDetails } from '@src/hooks/queries/report_service/report_items';
import { IReport } from '@src/types/report_service/report';
import { IItemAccount } from '@src/types/report_service/report_item';

import Table from '@src/components/ui_v2/table';

import ThirdDrillDownBody from './body';
import Breadcrumb from './breadcrumb';
import ThirdDrillDownHeader from './header';
import { IColumnNamesAndWidths } from './types';
import FullWidthModal from '../full_width_modal/index';

import styles from './styles.module.scss';

interface thirdDrillDownTableProps {
    report: IReport,
    item: IItemAccount,
    showThirdDrillDown: boolean,
    setShowThirdDrillDown: React.Dispatch<React.SetStateAction<boolean>>;
}

const ThirdDrillDownTable = ({
  report,
  item,
  showThirdDrillDown,
  setShowThirdDrillDown,
}: thirdDrillDownTableProps) => {
  const [showThirdDrillDownModal, setShowThirdDrillDownModal] = useState(false);
  const data = useGetLineItemDetails(report.id, item.reportDataId, report.slug, item.itemId, item.columnId, item.chartOfAccountId, 1);
  const columnNamesAndWidths:IColumnNamesAndWidths = { date: 8, transaction_type: 8, num: 8, name: 12, amount: 14, memo: 25, split: 25 };

  return (
    <Table.Cell colSpan={ 5 }>
      <FullWidthModal className={ styles['expanded-details'] } isOpen={ showThirdDrillDownModal } onClose={ () => setShowThirdDrillDownModal(false) }>
        { showThirdDrillDownModal && <Breadcrumb item={ item } onClick={ () => setShowThirdDrillDownModal(false) } />}
        <Table className={ styles['third-drill-down-table'] }>
          <ThirdDrillDownHeader
            columnNamesAndWidths={ columnNamesAndWidths }
            handleCollapseClick={ () => setShowThirdDrillDown(!showThirdDrillDown) }
            lineItemValues={ data.data || [] }
            setShowThirdDrillDownModal={ setShowThirdDrillDownModal }
            showThirdDrillDownModal={ showThirdDrillDownModal }
          />
          <ThirdDrillDownBody
            columnNamesAndWidths={ columnNamesAndWidths }
            itemId={ item.itemId }
            lineItemValues={ data.data || [] }
          />
        </Table>
      </FullWidthModal>
    </Table.Cell>
  );
};

export default React.memo(ThirdDrillDownTable);
