import React from 'react';

import { IItemAccount } from '@src/types/report_service/report_item';
import { formatFullMonthFullYear } from '@src/utils/date_helpers';

import Text from '@src/components/ui/text';
import DetailsRegion from '@src/components/ui_v2/layout/details_region';

import styles from './styles.module.scss';

interface IBreadcrumbProps {
  item: IItemAccount,
  onClick: () => void;
}

const Breadcrumb = ({ item, onClick }: IBreadcrumbProps): JSX.Element => {
  return (
    <DetailsRegion.Header
      className={ styles['blue-color'] }
      divider="horizontal"
      title={
        (
          <div>
            <Text className="m-l-10 m-r-5" fontSize={ 18 } inColor="black">{ formatFullMonthFullYear(item.reportMonth - 1, item.reportYear) }</Text>
            <span style={ { fontSize: '18px' } }>/</span>
            <Text className="m-l-5 m-r-5" fontSize={ 18 } inColor="black">{item.itemName}</Text>
            <span style={ { fontSize: '18px' } }>/</span>
            <Text className="m-l-5 m-r-5" fontSize={ 18 } inColor="black">{item.valueType}</Text>
            <span style={ { fontSize: '18px' } }>/</span>
            <Text className="m-l-5 m-r-5" fontSize={ 18 } inColor="black">{item.name}</Text>
          </div>
        )
      }
    >
      <div aria-hidden className="pull-right" role="button" onClick={ onClick }>
        <span><i className="glyphicon glyphicon-resize-small font-18" /></span>
      </div>
    </DetailsRegion.Header>
  );
};

export default Breadcrumb;
