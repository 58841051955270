import React, { useCallback, useMemo, useState } from 'react';

import classNames from 'classnames';

import { useGetLinkedBusinesses } from '@src/hooks/queries/businesses';
import { IBusinessVendor } from '@src/types/business_vendors';
import { capitalize } from '@src/utils/transform_keys';

import Tooltip from '@src/components/ui_v2/tooltip';
import { UnverifiedIcon } from '@src/components/utils/icomoon';

import { useActivateInvoiceModal } from './activate_invoice_modal';

import styles from '../styles.module.scss';

interface IVendorDetailProps {
  vendor: IBusinessVendor,
  show: boolean,
}

const VendorDetail = ({ vendor, show } : IVendorDetailProps) => {
  const modal = useActivateInvoiceModal();

  const showClasses = classNames(
    styles.item,
    styles['item-show-status'],
    {
      [styles.hide]: !show,
    },
  );

  const LineItemsDetection = useCallback(() => {
    if (vendor.isAiInvoiceLineItemsExtraction) {
      return (
        <p>Activated</p>
      );
    }
    return (
      <p>Deactivated</p>
    );
  }, [vendor, modal.open]);

  const [isHovered, setIsHovered] = useState<boolean>(false);

  // Fetch linked businesses
  const query = useGetLinkedBusinesses({
    id:       vendor.businessId,
    vendorId: vendor.id,
  });

  const linkedBusinesses = useMemo(() => query.data?.businesses || [], [query.data?.businesses]);

  return (
    <>
      <div className={ styles.content }>
        <div className={ styles.item }>
          <div className={ styles['label-item'] }>
            <p>Name</p>
          </div>
          <div className={ styles['value-item'] }>
            <p>{ vendor.name ? vendor.name : '-' }</p>
          </div>
        </div>
        <div className={ styles.item }>
          <div className={ styles['label-item'] }>
            <p>Website</p>
          </div>
          <div className={ styles['value-item'] }>
            { vendor.website ? (
              <a href={ `mailto:${vendor.website}` }>{ vendor.website }</a>
            ) : (
              <p>-</p>
            ) }
          </div>
        </div>
        <div className={ styles.item }>
          <div className={ styles['label-item'] }>
            <p>Email</p>
          </div>
          <div className={ styles['value-item'] }>
            <p>{ vendor.email ? vendor.email : '-' }</p>
          </div>
        </div>
        <div className={ styles.item }>
          <div className={ styles['label-item'] }>
            <p>Phone Number</p>
          </div>
          <div className={ styles['value-item'] }>
            <p>{ vendor.phone ? vendor.phone : '-' }</p>
          </div>
        </div>
        <div className={ styles.item }>
          <div className={ styles['label-item'] }>
            <p>Fax</p>
          </div>
          <div className={ styles['value-item'] }>
            <p>{ vendor.fax ? vendor.fax : '-' }</p>
          </div>
        </div>
        <div className={ styles.item }>
          <div className={ styles['label-item'] }>
            <p>Preferred Payment Method</p>
          </div>
          <div className={ styles['value-item'] }>
            <p>{ vendor.preferredPaymentMethod ? vendor.preferredPaymentMethod : '-' }</p>
          </div>
        </div>
        <div className={ styles.item }>
          <div className={ styles['label-item'] }>
            <p>ACH Status</p>
          </div>
          <div className={ styles['value-item'] }>
            <p>{ vendor.achStatus ? capitalize(vendor.achStatus) : '-' }</p>
          </div>
        </div>
        <div className={ styles.item }>
          <div className={ styles['label-item'] }>
            <p>AI Invoice Line Items Detection</p>
            <Tooltip.Hover
              content="This will help extract the line items from an invoice automatically.
              You will be able to edit the data. Docyt AI will learn from manually input data."
            >
              <UnverifiedIcon fontSize={ 14 } />
            </Tooltip.Hover>
          </div>
          <div className={ styles['value-item'] }>
            <LineItemsDetection />
          </div>
        </div>
        <div className={ showClasses }>
          <div className={ styles['label-item'] }>
            <p>Associated Businesses</p>
          </div>
          <div
            className={ styles['value-item'] }
            onMouseEnter={ () => setIsHovered(true) }
            onMouseLeave={ () => setIsHovered(false) }
          >
            { linkedBusinesses.length > 0 ? (
              <>
                <span>
                  { linkedBusinesses.length }
                  more
                </span>
                { isHovered && (
                  <div className={ styles['linked-businesses'] }>
                    { linkedBusinesses.map((business) => (
                      <p key={ business.id } className={ styles['linked-business-item'] }>
                        { business.name }
                      </p>
                    )) }
                  </div>
                ) }
              </>
            ) : (
              <p>-</p>
            ) }
          </div>
        </div>
      </div>
      <modal.Component { ...modal.props } businessId={ vendor.businessId } />
    </>
  );
};

export default React.memo(VendorDetail);
