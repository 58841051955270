import { useMutation, useQueryClient, InfiniteData, useQuery } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import {
  downloadDocument,
  downloadDocuments,
  IDownloadDocumentParams,
  IDownloadFileParams,
  IDownloadDocumentResponse,
  IForwardDocumentsParams,
  IForwardDocumentsToServiceParams,
  IMarkAsReadDocumentsParams,
  IMarkAsReadDocumentsResponse,
  IMoveDocumentsToTrashParams,
  IUploadDocumentParams,
  forwardDocuments,
  forwardDocumentsToService,
  markDocumentAsRead,
  moveDocumentsToTrash,
  uploadDocument,
  IDownloadFileResponse,
  IForwardDocumentResponse,
  IGetDocumentsParams,
  IGetDocumentsResponse,
  getDocuments,
  IGetDocumentRelatedDocumentsParams,
  IGetDocumentRelatedDocumentsResponse,
  getDocumentRelatedDocuments,
  IDeleteDocumentParams,
  IDeleteDocumentsParams,
  deleteDocument,
  deleteDocuments,
  getDocument,
  IPutDocumentParams,
  putDocument,
  IPutUpdateStarredFlagParams,
  putUpdateStarredFlag,
  IForwardToBankStatementsParams,
  forwardToBankStatements,
  getDocumentsByTransactionId,
  IGetDocumentsByTransactionIdParams,
  IGetRelatedDocumentsByAchTransactionParams,
  getRelatedDocumentsByAchTransaction,
  IForwardToBalanceSheetkStatementsParams,
  forwardToBalanceSheetStatements,
  IDeleteMatchedDocumentParams,
  deleteMatchedDocument,
} from '@src/requests/documents';
import { apiPost, apiPut, apiDelete } from '@src/requests/helpers';
import { IGetInboxServiceDocumentsResponse } from '@src/requests/inbox_service_documents';
import { UploadToS3Service } from '@src/services/upload_to_s3_service';
import { TID } from '@src/types/common';
import { IDocument } from '@src/types/documents';
import { IInboxServiceDocument } from '@src/types/inbox_service_documents';
import { IRelatedDocument } from '@src/types/related_documents';

import { IBulkMutationParams, makeBulkRequest, IBulkMutationReturn } from './bulk_mutations';
import { INBOX_SERVICE_DOCUMENTS_QUERY } from './inbox_service_documents';
import { INBOX_SERVICE_QUERY } from './inbox_services';
import {
  removeItemsFromInfiniteCollection,
  updateItemsInInfiniteCollection,
  createUseGetInfiniteCollection,
} from './infinite_collection_queries';

const DOCUMENTS_QUERY = 'documents';

const useMarkDocumentsAsRead = () => {
  const queryClient = useQueryClient();

  return useMutation<
    IMarkAsReadDocumentsResponse,
    Error,
    IMarkAsReadDocumentsParams
  >(
    markDocumentAsRead,
    {
      onSuccess: (response, params) => {
        queryClient.setQueriesData(
          INBOX_SERVICE_DOCUMENTS_QUERY,
          (
            data?: InfiniteData<IGetInboxServiceDocumentsResponse>,
          ): InfiniteData<IGetInboxServiceDocumentsResponse> => {
            return updateItemsInInfiniteCollection<
              IInboxServiceDocument,
              IGetInboxServiceDocumentsResponse
            >(
              data,
              params.documentIds,
              { viewedAt: response.viewedAt },
            );
          },
        );
      },
    },
  );
};

const useBulkMarkDocumentsAsRead = () => {
  const queryClient = useQueryClient();

  return useMutation<
    IBulkMutationReturn<IMarkAsReadDocumentsResponse>,
    Error,
    IBulkMutationParams<IMarkAsReadDocumentsParams, IMarkAsReadDocumentsResponse>
  >(
    makeBulkRequest(markDocumentAsRead),
    {
      onSettled: () => {
        return queryClient.invalidateQueries(INBOX_SERVICE_DOCUMENTS_QUERY);
      },
    },
  );
};

const useBulkMoveDocumentsToTrash = () => {
  const queryClient = useQueryClient();

  return useMutation<
    IBulkMutationReturn,
    Error,
    IBulkMutationParams<IMoveDocumentsToTrashParams>
  >(
    makeBulkRequest(moveDocumentsToTrash),
    {
      onSettled: () => {
        return queryClient.invalidateQueries(INBOX_SERVICE_QUERY)
          .then(() => queryClient.invalidateQueries(INBOX_SERVICE_DOCUMENTS_QUERY));
      },
    },
  );
};

const useMoveDocumentsToTrash = () => {
  const queryClient = useQueryClient();

  return useMutation<void, Error, IMoveDocumentsToTrashParams>(
    moveDocumentsToTrash,
    {
      onSuccess: (response, params) => {
        queryClient.invalidateQueries(INBOX_SERVICE_QUERY);
        queryClient.invalidateQueries(QueryKey.ExpenseReportsServiceDocuments);
        queryClient.setQueriesData(
          INBOX_SERVICE_DOCUMENTS_QUERY,
          (
            data?: InfiniteData<IGetInboxServiceDocumentsResponse>,
          ): InfiniteData<IGetInboxServiceDocumentsResponse> => {
            return removeItemsFromInfiniteCollection(
              data,
              params.documentIds,
            );
          },
        );
      },
    },
  );
};

const useBulkForwardDocuments = () => {
  const queryClient = useQueryClient();

  return useMutation<
    IBulkMutationReturn<IForwardDocumentResponse>,
    Error,
    IBulkMutationParams<IForwardDocumentsParams, IForwardDocumentResponse>
  >(
    makeBulkRequest((params: IForwardDocumentsParams) => {
      return forwardDocuments(params)
        .then((data) => {
          return moveDocumentsToTrash({ documentIds: params.documentIds, value: true })
            .then(() => data);
        });
    }),
    {
      onSettled: () => {
        return queryClient.invalidateQueries(INBOX_SERVICE_QUERY)
          .then(() => queryClient.invalidateQueries(INBOX_SERVICE_DOCUMENTS_QUERY));
      },
    },
  );
};

const useForwardDocuments = () => {
  return useMutation<IForwardDocumentResponse, Error, IForwardDocumentsParams>(
    (params: IForwardDocumentsParams) => {
      return forwardDocuments(params)
        .then((data) => {
          return moveDocumentsToTrash({ documentIds: params.documentIds, value: true })
            .then(() => data);
        });
    },
  );
};

const useBulkForwardDocumentsToService = () => {
  const queryClient = useQueryClient();

  return useMutation<
    IBulkMutationReturn,
    Error,
    IBulkMutationParams<IForwardDocumentsToServiceParams>
  >(
    makeBulkRequest((params: IForwardDocumentsToServiceParams) => {
      return forwardDocumentsToService(params)
        .then((data) => {
          return moveDocumentsToTrash({ documentIds: params.documentIds, value: true })
            .then(() => data);
        });
    }),
    {
      onSettled: () => {
        return queryClient.invalidateQueries(INBOX_SERVICE_QUERY)
          .then(() => queryClient.invalidateQueries(INBOX_SERVICE_DOCUMENTS_QUERY));
      },
    },
  );
};

const useForwardDocumentsToService = () => {
  return useMutation<void, Error, IForwardDocumentsToServiceParams>(
    (params: IForwardDocumentsToServiceParams) => {
      return forwardDocumentsToService(params)
        .then((data) => {
          return moveDocumentsToTrash({ documentIds: params.documentIds, value: true })
            .then(() => data);
        });
    },
  );
};

const useDownloadDocument = () => {
  return useMutation<IDownloadDocumentResponse, Error, IDownloadDocumentParams>(downloadDocument);
};

const useDownloadDocuments = () => {
  return useMutation<IDownloadFileResponse, Error, IDownloadFileParams>(downloadDocuments);
};

const useUploadDocument = () => {
  const queryClient = useQueryClient();

  return useMutation<void, Error, IUploadDocumentParams>(
    uploadDocument,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(INBOX_SERVICE_QUERY);
        queryClient.invalidateQueries(INBOX_SERVICE_DOCUMENTS_QUERY);
      },
    },
  );
};

const useUpdateStarredFlag = () => {
  return useMutation<void, Error, IPutUpdateStarredFlagParams>(putUpdateStarredFlag);
};

const useGetDocuments = createUseGetInfiniteCollection<
  IDocument,
  IGetDocumentsParams,
  IGetDocumentsResponse
>({
  queryKey: DOCUMENTS_QUERY,
  request:  getDocuments,
});

const useDeleteMatchedDocument = () => {
  const queryClient = useQueryClient();

  return useMutation<void, Error, IDeleteMatchedDocumentParams>(
    deleteMatchedDocument,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.matchedDocumentsQuery);
      },
    },
  );
};

const useDestroyDocument = () => {
  const queryClient = useQueryClient();

  return useMutation<void, Error, IDeleteDocumentParams>(
    deleteDocument,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(DOCUMENTS_QUERY);
        window.Docyt.vent.trigger('document:destroyed');
      },
    },
  );
};

const useDestroyBatchDocument = () => {
  const queryClient = useQueryClient();

  return useMutation<void, Error, IDeleteDocumentsParams>(
    deleteDocuments,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.ExpenseReportsServiceDocuments);
      },
    },
  );
};

const useGetDocument = (documentId: number) => {
  return useQuery<IDocument, Error>(
    [QueryKey.document, documentId],
    () => getDocument(documentId),
  );
};

const useGetDocumentRelatedDocuments = createUseGetInfiniteCollection<
  IRelatedDocument,
  IGetDocumentRelatedDocumentsParams,
  IGetDocumentRelatedDocumentsResponse
>({
  queryKey: QueryKey.relatedDocuments,
  request:  getDocumentRelatedDocuments,
});

const useUpdateDocument = () => {
  const queryClient = useQueryClient();

  return useMutation<void, Error, IPutDocumentParams>(
    putDocument,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.document);
      },
    },
  );
};

const useForwardToBankStatements = () => {
  const queryClient = useQueryClient();

  return useMutation<void, Error, IForwardToBankStatementsParams>(
    (params: IForwardToBankStatementsParams) => {
      return forwardToBankStatements(params)
        .then(() => {
          return moveDocumentsToTrash({ documentIds: [params.id], value: true })
            .then(() => {
              return queryClient.invalidateQueries(INBOX_SERVICE_QUERY)
                .then(() => queryClient.invalidateQueries(INBOX_SERVICE_DOCUMENTS_QUERY));
            });
        });
    },
  );
};

const useForwardToBalanceSheetStatements = () => {
  const queryClient = useQueryClient();

  return useMutation<void, Error, IForwardToBalanceSheetkStatementsParams>(
    (params: IForwardToBalanceSheetkStatementsParams) => {
      return forwardToBalanceSheetStatements(params)
        .then(() => {
          return moveDocumentsToTrash({ documentIds: [params.id], value: true })
            .then(() => {
              return queryClient.invalidateQueries(INBOX_SERVICE_QUERY)
                .then(() => queryClient.invalidateQueries(INBOX_SERVICE_DOCUMENTS_QUERY));
            });
        });
    },
  );
};

const useGetDocumentsByTransactionId = (
  params: IGetDocumentsByTransactionIdParams,
) => {
  return useQuery<IGetDocumentsResponse, Error>(
    [QueryKey.documentsByTransactionId, params],
    () => getDocumentsByTransactionId(params),
  );
};

const useGetRelatedDocumentsByAchTransaction = (
  params: IGetRelatedDocumentsByAchTransactionParams,
) => {
  return useQuery<IGetDocumentsResponse, Error>(
    [QueryKey.documentsByAchTransaction, params],
    () => getRelatedDocumentsByAchTransaction(params),
  );
};

interface UploadMailroomDocumentVariables {
  file: File;
  businessId: TID;
  documentRequestId: TID;
}

const useUploadMailroomDocument = () => {
  return useMutation({
    mutationFn: async ({ file, businessId, documentRequestId }: UploadMailroomDocumentVariables) => {
      // 1. Create document record
      const createResponse = await apiPost('/api/v1/documents', {
        business_id: businessId,
        document:    {
          original_file_name:  file.name,
          file_content_type:   file.type,
          storage_size:        file.size,
          document_request_id: documentRequestId,
        },
      }) as { document: IDocument };

      // 2. Complete document upload to get S3 details
      const completeResponse = await apiPut(`/api/v1/documents/${createResponse.document.id}/complete_upload`, {
        document: {
          file_content_type:  file.type,
          original_file_name: file.name,
          storage_size:       file.size,
          final_file_key:     `${createResponse.document.id}/${file.name}`,
          s3_object_key:      `documents/${createResponse.document.id}/${file.name}`,
        },
      });

      // 3. Upload to S3
      await UploadToS3Service.uploadFileToS3({
        file,
        s3ObjectKey: completeResponse.s3_object_key,
        document:    createResponse.document,
      });

      return createResponse.document;
    },
  });
};

interface DeleteDocumentVariables {
  documentId: TID;
}

const useDeleteDocument = () => {
  return useMutation({
    mutationFn: async ({ documentId }: DeleteDocumentVariables) => {
      await apiDelete(`/api/web/v1/documents/${documentId}`);
    },
  });
};

export {
  useBulkForwardDocuments,
  useBulkForwardDocumentsToService,
  useBulkMarkDocumentsAsRead,
  useBulkMoveDocumentsToTrash,
  useDownloadDocument,
  useDownloadDocuments,
  useForwardDocuments,
  useForwardDocumentsToService,
  useMarkDocumentsAsRead,
  useMoveDocumentsToTrash,
  useUploadDocument,
  useGetDocuments,
  useDestroyDocument,
  useDestroyBatchDocument,
  useGetDocument,
  useGetDocumentRelatedDocuments,
  useUpdateDocument,
  useUpdateStarredFlag,
  useForwardToBankStatements,
  useForwardToBalanceSheetStatements,
  useGetDocumentsByTransactionId,
  useGetRelatedDocumentsByAchTransaction,
  useUploadMailroomDocument,
  useDeleteDocument,
  useDeleteMatchedDocument,
};
