/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo } from 'react';

import { omit } from 'lodash';
import flatten from 'lodash/flatten';
import { UseInfiniteQueryResult } from 'react-query';

import { useGetTaskServiceDocuments } from '@src/hooks/queries/task_service_documents';
import { useSorting } from '@src/hooks/url_params';
import { IGetTaskServiceDocumentsResponse } from '@src/requests/task_service_documents';
import { TSection } from '@src/types/common';
import { ISorting, ISortingParams } from '@src/types/sorting';
import { ITaskServiceDocument, TTasksDocumentsSortColumn } from '@src/types/task_service_documents';

import { useFilterData } from '@src/components/ui_v2/filter';

interface IUseTaskCollectionParams {
  status: string[]
  tab: keyof typeof window.Docyt.Common.Constants.TASKS
}

export interface ITasksCollection {
  query: UseInfiniteQueryResult<IGetTaskServiceDocumentsResponse, Error>,
  records: ITaskServiceDocument[],
  section: TSection,
  sorting: ISorting<TTasksDocumentsSortColumn>,
}

const tasksDefaultSorting: ISortingParams<TTasksDocumentsSortColumn> = {
  orderColumn:    'taskId',
  orderDirection: 'desc',
};

const getTasksParamsFromFilter = (filterData: any) => {
  const filterParams = omit(filterData || {});
  return {
    filter: filterParams,
  };
};

const useTaskServiceCollection = ({
  status,
  tab,
}: IUseTaskCollectionParams): ITasksCollection => {
  const defaultFilter = useMemo(() => {
    return { status };
  }, [tab, status]);
  const section = useMemo(() => {
    return {
      tab,
      section: tab,
    };
  }, [tab]);

  const filterData = useFilterData(section, defaultFilter);

  const filterParams = useMemo(() => {
    return getTasksParamsFromFilter(filterData);
  }, [filterData]);

  const sorting = useSorting<TTasksDocumentsSortColumn>({
    section:        section.section,
    defaultSorting: tasksDefaultSorting,
  });

  const query = useGetTaskServiceDocuments({
    page: 1,
    ps:   20,
    ...filterParams,
    ...sorting.data,
  });

  const records = useMemo(() => {
    const pages = query?.data?.pages || [];
    return flatten(pages.map((p) => p.collection));
  }, [query?.data?.pages]);

  return {
    query,
    records,
    section,
    sorting,
  };
};

export {
  IUseTaskCollectionParams,
  useTaskServiceCollection,
};
