import React, { useCallback, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import toastr from '@lib/toastr';
import { useUpdateTaskServiceDocument } from '@src/hooks/queries/task_service_documents';
import { ICreateTaskBody } from '@src/requests/task_service_documents';
import { TSection } from '@src/types/common';
import { ITaskServiceDocument } from '@src/types/task_service_documents';

import { Button } from '@src/components/ui_v2/buttons';
import Form from '@src/components/ui_v2/form';
import { TOption } from '@src/components/ui_v2/inputs';
import { SpinnerIcon } from '@src/components/utils/fa_icons';
import Section from '@src/components/utils_v2/section';

import AssigneeField from './assignee';
import { allStatusOptions, getChangedFields } from './helper/constants';
import { ITaskItemFormValues, updateTaskValidation } from './helper/validations';
import TaskBusinessSelect from './task_business_select';
import TaskDeleteModal from './task_delete_modal';
import TaskStatusSelect from './task_status_select';
import { StatusKey } from '../types';

import styles from '../styles.module.scss';

interface DetailsSectionProps {
  taskServiceDocument: ITaskServiceDocument;
  onGoBack:()=>void;
}

const DetailsSection = ({ taskServiceDocument, onGoBack }:DetailsSectionProps) => {
  const [selectedBusiness, setSelectedBusiness] = useState<TOption | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const updateTask = useUpdateTaskServiceDocument();

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);
  const {
    formState: { errors },
    register,
    handleSubmit,
    setValue,
  } = useForm<ITaskItemFormValues>({
    defaultValues: {
      userId:      taskServiceDocument.userId,
      businessId:  taskServiceDocument.businessId,
      title:       taskServiceDocument.title,
      status:      taskServiceDocument.status,
      description: taskServiceDocument.description,
    },
    resolver:         yupResolver(updateTaskValidation),
    reValidateMode:   'onChange',
    shouldFocusError: true,
  });
  const handleCloseDelete = useCallback(() => {
    onGoBack();
    handleClose();
  }, [handleClose, onGoBack]);

  const submitHandler = handleSubmit((bodyData) => {
    const prevValues = {
      userId:      taskServiceDocument.userId,
      businessId:  Number(taskServiceDocument.businessId),
      title:       taskServiceDocument.title,
      status:      taskServiceDocument.status,
      description: taskServiceDocument.description,
    };
    const changedFields = getChangedFields(bodyData, prevValues);

    if (Object.keys(changedFields).length) {
      updateTask.mutate(
        {
          formData: changedFields as ICreateTaskBody,
          taskId:   taskServiceDocument.taskId,
        },
        {
          onSuccess: () => {
            toastr.success(
              'Task Updated Successfully',
              'Create Task',
            );
            handleClose();
          },
          onError: (error) => {
            toastr.error(
              error.message,
              'Error Update Task',
            );
          },
        },
      );
    }
  });

  return (
    <div className={ styles['left-section'] }>
      <p className={ styles['logs-header'] }>Details</p>
      <div className={ styles['divider-bottom'] } />
      <Form
        onSubmit={ submitHandler }
      >
        <div className={ styles['form-row'] }>
          <label htmlFor="business">Business:</label>
          <div className={ styles['task-detail-business'] }>
            <TaskBusinessSelect
              businessId={ taskServiceDocument?.businessId }
              selectedBusiness={ selectedBusiness }
              setSelectedBusiness={ (e) => {
                setSelectedBusiness(e);
                setValue('businessId', Number(e?.value));
              } }
            />
          </div>
          {errors.businessId?.message
           && <span className={ styles['error-message'] }>{errors.businessId?.message}</span>}
        </div>

        <div className={ styles['form-row'] }>
          <label htmlFor="status">Status:</label>
          <div className={ styles['task-details-status'] }>
            <TaskStatusSelect
              className={ styles['task-status-select-detail-container'] }
              menuClassName={ styles['task-status-select-menu-details'] }
              selectedValue={ taskServiceDocument?.status as StatusKey }
              statusOptions={ allStatusOptions }
              onChange={ (e) => {
                setValue('status', e?.value);
              } }
            />
          </div>
        </div>

        <Section.Provider section={ { section: 'assignee' } as TSection }>
          <div className={ styles['form-row'] }>
            <label htmlFor="assignee">Assignee:</label>
            <div className={ styles['task-details-assignee'] }>
              <AssigneeField
                isBorder
                businessId={ taskServiceDocument.businessId }
                className={ styles['task-assignee-inner'] }
                isDisabled={ !taskServiceDocument?.canEdit }
                selectedUser={ {
                  icon:   taskServiceDocument.userIcon,
                  label:  taskServiceDocument?.userName,
                  helper: taskServiceDocument.userDesignation,
                  value:  String(taskServiceDocument.userId),
                } }
                onChange={ (option) => {
                  setValue('userId', Number(option?.value));
                } }
              />
            </div>
          </div>
        </Section.Provider>

        <div className={ styles['task-feild-title'] }>
          <label htmlFor="title">Title:</label>
          <Form.TextField
            hiddenLabel
            defaultValue={ taskServiceDocument?.title }
            error={ errors.title?.message }
            label=""
            { ...register('title') }
          />
        </div>

        <div className={ styles['task-feild-description'] }>
          <label htmlFor="description">Description:</label>
          <Form.TextAreaField
            hiddenLabel
            className={ styles['task-feild-description-textarea'] }
            defaultValue={ taskServiceDocument?.description }
            error={ errors.description?.message }
            label=""
            { ...register('description') }
          />
        </div>
        <div className={ styles['delete-divider-bottom'] } />
        <div className={ styles['btn-bottom-container'] }>
          <Button
            className={ styles['task-delete-btn'] }
            type="button"
            variant="link"
            onClick={ () => setOpen(true) }
          >
            Delete
          </Button>
          <Button
            className={ styles['task-update-btn'] }
            disabled={ updateTask.isLoading }
            type="submit"
            variant="primary"
          >
            {updateTask.isLoading ? (
              <>
                Updating
                {' '}
                <SpinnerIcon spin fontSize={ 10 } />
              </>
            )
              : 'Update'}
          </Button>
        </div>
      </Form>
      <TaskDeleteModal
        open={ open }
        taskId={ taskServiceDocument?.taskId }
        onCancel={ handleClose }
        onDelete={ handleCloseDelete }
      />
    </div>
  );
};

export default DetailsSection;
