import React, { useCallback, useMemo, useState } from 'react';

import { GroupBase } from 'react-select';
import { LoadOptions } from 'react-select-async-paginate';

import { getSearchTaskUsers } from '@src/requests/task_service_documents';
import { TID } from '@src/types/common';

import { VendorAvatar } from '@src/components/ui/avatars';
import { IFilterFieldUIProps } from '@src/components/ui/filter/utils';
import {
  IIconSelectInputProps,
  TIconOption,
} from '@src/components/ui_v2/inputs';

import { ASSIGNEE_USERS_PER_PAGE } from './helper/constants';
import { TaskAssigneeSelect } from './task_assignee_select';
import { ITaskIconOption, ITaskUserOptionItem } from '../types';

interface IAssigneeSelectFilterFieldProps extends
  Omit<IIconSelectInputProps, 'name' | 'value' | 'onChange'>, IFilterFieldUIProps
{
  businessId: TID;
  toggleElement?: string | React.ReactNode;
  className?: string;
  isBorder?: boolean;
  selectedUser?: ITaskUserOptionItem;
  onChange?:(newValue: ITaskIconOption | TIconOption | null | undefined)=>void;
}

const AssigneeField = ({
  businessId,
  toggleElement,
  isBorder,
  className = '',
  selectedUser,
  onChange,
}: IAssigneeSelectFilterFieldProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const handleSource: LoadOptions<TIconOption, GroupBase<TIconOption>, any> =
  useCallback(async (query, options, { page }) => {
    if (!businessId) {
      return {
        hasMore:    false,
        options:    [],
        additional: { page },
      };
    }

    setIsLoading(true);

    try {
      const data = await getSearchTaskUsers({
        businessId,
        filter:  { name: query },
        page,
        perPage: ASSIGNEE_USERS_PER_PAGE,
      });

      const hasMore = data.collection?.length > 0;
      const newOptions = data.collection.map((vendor) => ({
        label:  vendor?.parsedFullname,
        value:  String(vendor.id),
        icon:   <VendorAvatar size="100%" vendor={ { ...vendor, imageUrl: vendor?.avatar?.avatarUrl } } />,
        helper: vendor?.title,
      }));

      return {
        hasMore,
        options:    newOptions,
        additional: {
          page: page + 1,
        },
      };
    } catch {
      return {
        hasMore:    false,
        options:    [],
        additional: { page },
      };
    } finally {
      setIsLoading(false);
    }
  }, [businessId]);

  const selectedItem = useMemo(() => {
    if (!selectedUser?.value) return undefined;

    return {
      icon: <VendorAvatar
        size="100%"
        vendor={ { ...selectedUser,
          imageUrl: String(selectedUser.icon) } }
      />,
      label:  selectedUser?.label,
      helper: selectedUser?.helper,
      value:  selectedUser?.value,
    };
  }, [selectedUser]);
  return (
    <TaskAssigneeSelect
      className={ className }
      handleSource={ handleSource }
      isBorder={ isBorder }
      isLoading={ isLoading }
      selectedItem={ selectedItem }
      toggleElement={ toggleElement }
      onChange={ onChange }
    />
  );
};

export default React.memo(AssigneeField);
