import React, { useMemo, useRef } from 'react';

import { flatten } from 'lodash';

import { useGetTaskServiceLogsDocumentLogs } from '@src/hooks/queries/task_service_documents';
import { useInfiniteRegionScroll } from '@src/hooks/scroll';
import { formatDate, getDateGap } from '@src/utils/date_helpers';

import LocalQueryStatus from '@src/components/utils/local_query_status';

import { logsDateFormat } from './helper/constants';

import styles from '../styles.module.scss';

interface ITasksLogsProps {
  taskId: string;
}

const TasksLogs = ({ taskId }: ITasksLogsProps) => {
  const query = useGetTaskServiceLogsDocumentLogs({
    page: 1,
    ps:   10,
    taskId,
  });
  const wrapperRef = useRef<HTMLDivElement>(null);
  useInfiniteRegionScroll({
    elementRef: wrapperRef,
    query,
  });

  const records = useMemo(() => {
    const pages = query?.data?.pages || [];
    return flatten(pages.map((p) => p.collection));
  }, [query?.data?.pages]);
  return (
    <div className={ styles['right-section'] }>
      <p className={ styles['logs-header'] }>
        Logs
      </p>
      <div className={ styles['divider-bottom'] } />
      <div className={ styles['log-section'] }>
        <div
          ref={ wrapperRef }
          className={ styles['logs-container'] }
        >
          <ul>
            {records?.length ? (
              records.map((entry) => (
                <li key={ entry.id } className={ styles['log-item'] }>
                  <div className={ styles['log-meta'] }>
                    <span>{formatDate(new Date(entry?.createdAt), logsDateFormat)}</span>
                    {entry.createdAt && (
                    <small
                      className={
                       getDateGap(entry.createdAt, 'later')?.isGreaterOrEqualTwoDays
                         ? styles['red-text']
                         : ''
                     }
                    >
                      {getDateGap(entry.createdAt, 'later')?.gap}
                      {' '}
                    </small>
                    )}
                  </div>
                  <p className={ styles['log-details'] }>{entry?.notes}</p>
                </li>
              ))
            ) : (
              !query.isLoading && <li className={ styles['no-logs'] }>No Logs Found</li>
            )}
          </ul>
          <LocalQueryStatus query={ query } />
        </div>
      </div>
    </div>
  );
};

export default TasksLogs;
