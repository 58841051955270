import { IBalanceSheetStatement } from '@src/types/balance_sheet_statements';
import { IBankStatement } from '@src/types/bank_statements';
import { TID } from '@src/types/common';
import { camelizeKeys } from '@src/utils/transform_keys';

import { apiPut } from './helpers';

interface IUploadBankStatementAttachmentParams {
  businessId: TID;
  bankStatementId: TID;
  file: File;
}

interface IUploadBankStatementAttachmentResponse {
  bankStatement: IBankStatement;
}

const uploadBankStatementAttachment = async (
  params: IUploadBankStatementAttachmentParams,
): Promise<IUploadBankStatementAttachmentResponse> => {
  const formData = new FormData();
  formData.append('business_id', String(params.businessId));
  formData.append('file', params.file);

  return apiPut(
    `/api/v1/bank_statements/${params.bankStatementId}/upload_attachment`,
    formData,
  ).then((data) => camelizeKeys(data) as IUploadBankStatementAttachmentResponse);
};

export {
  IUploadBankStatementAttachmentParams,
  IUploadBankStatementAttachmentResponse,
  uploadBankStatementAttachment,
};

// Add these new interfaces
export interface IUploadBalanceSheetStatementAttachmentParams {
  businessId: number;
  balanceSheetStatementId: number;
  file: File;
}

export interface IUploadBalanceSheetStatementAttachmentResponse {
  balanceSheetStatement: IBalanceSheetStatement;
}

export const uploadBalanceSheetStatementAttachment = async ({
  businessId,
  balanceSheetStatementId,
  file,
}: IUploadBalanceSheetStatementAttachmentParams): Promise<IUploadBalanceSheetStatementAttachmentResponse> => {
  const formData = new FormData();
  formData.append('business_id', String(businessId));
  formData.append('file', file);

  return apiPut(
    `/api/v1/balance_sheet_statements/${balanceSheetStatementId}/upload_attachment`,
    formData,
  ).then((data) => camelizeKeys(data) as IUploadBalanceSheetStatementAttachmentResponse);
};
