import React, { useCallback, useState } from 'react';

import toastr from '@lib/toastr';
import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { useRevenueServiceContext } from '@src/hooks/contexts/revenue_service_context';
import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { useUpsertIntegration } from '@src/hooks/queries/integrations/integrations';
import { useCreateRevenueReportType } from '@src/hooks/queries/revenue_report_types';
import { ICreateRevenueReportTypeResponse } from '@src/requests/revenue_report_types';
import { revenueServiceMappingPath } from '@src/routes';
import { TID } from '@src/types/common';
import { IIntegration } from '@src/types/integrations/integrations';

import Modal from '@src/components/ui/modal';
import { ErrorNotification } from '@src/components/ui/notification';
import MutationStatus from '@src/components/utils/mutation_status';

import { useRevenueCategoriesData, useStepManager } from '../hooks';
import Form from './form';
import { IAddReportTypeValues } from './schema';
import {
  useChangeMappingRevenueReportModal,
} from '../../revenue_capture/modal/change_mapping_revenue_report_action_modal';

import styles from '../styles.module.scss';

const STEP_TITLES: Record<number, string> = {
  1: 'Add New Revenue Mapping',
  2: 'Select Data Frequency',
  3: 'Select Revenue by Payment Processor Tracking',
  4: 'Select Revenue Categories',
  5: 'Select Tax Categories',
  6: 'Select Other Ledgers Categories',
};

interface IAddReportTypeModalProps extends IUseModalProps {
  changeMapping?: boolean,
  integration?: IIntegration,
}

const AddReportTypeModal = ({
  isOpen,
  onDone,
  onCancel: onCloseModal,
  changeMapping = false,
  integration,
}: IAddReportTypeModalProps) => {
  const business = useBusinessContext();
  const revenueService = useRevenueServiceContext();
  const { categories, taxes, otherLedgers } = useRevenueCategoriesData(business.id);

  const mutation = useCreateRevenueReportType();
  const { mutate: createRevenueReportType } = mutation;

  const upsert = useUpsertIntegration();
  const { mutate } = upsert;

  const { step, nextStep, previousStep } = useStepManager();
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  const changeMappingRevenueReportModal = useChangeMappingRevenueReportModal();

  const handleError = useCallback((response) => {
    setErrorMessage(response?.response?.data?.errors[0]);
  }, [setErrorMessage]);

  const handleSuccess = useCallback((revenueReportTypeId:TID) => {
    const url = revenueServiceMappingPath(business.id, revenueReportTypeId);
    Backbone.history.navigate(url, { trigger: true });
    onDone();
  }, [business.id, onDone]);

  const handleSubmit = useCallback(
    (values: IAddReportTypeValues) => {
      const TOTAL_STEPS = Object.keys(STEP_TITLES).length;
      if (step < TOTAL_STEPS) {
        nextStep();
      } else {
        createRevenueReportType(
          {
            revenueId:         revenueService.id,
            revenueReportType: {
              name:                    values.name,
              frequency:               values.frequency,
              splitRevenueReceivables: values.split_revenue_receivables,
              categoryIds:             values.category_ids,
              taxIds:                  values.tax_ids,
              ledgerIds:               values.ledger_ids,
            },
          },
          {
            onSuccess: (data: ICreateRevenueReportTypeResponse) => {
              if (integration) {
                mutate({
                  businessId:  business.id,
                  integration: {
                    externalSystemId:     integration.externalSystemId,
                    startDate:            integration.externalSystemConfig?.startDate?.toString() || '',
                    revenueReportTypeId:  data.revenueReportType.id,
                    externalSystemConfig: integration.integrationType === 'pull' ? integration.externalSystemConfig : {},
                    location:             integration.externalSystemConfig?.location?.toString() || '',
                  },
                  actionType: 'update',
                }, {
                  onSuccess: () => {
                    const url = revenueServiceMappingPath(business.id, data.revenueReportType.id);
                    Backbone.history.navigate(url, { trigger: true });
                    onDone();
                  },
                  onError: handleError,
                });
              } else {
                const url = revenueServiceMappingPath(business.id, data.revenueReportType.id);
                Backbone.history.navigate(url, { trigger: true });
                onDone();
              }
            },
            onError: handleError,
          },
        );
      }
    },
    [
      step,
      nextStep,
      createRevenueReportType,
      revenueService.id,
      handleError,
      integration,
      business.id,
      onDone,
      mutate,
    ],
  );

  const handleCancel = useCallback(() => {
    if (step > 1) {
      previousStep();
    } else {
      onCloseModal();
      if (changeMapping) {
        changeMappingRevenueReportModal.open();
      }
    }
  }, [onCloseModal, previousStep, step, changeMapping, changeMappingRevenueReportModal]);

  return (
    <>
      <MutationStatus mutation={ mutation } />
      {errorMessage && <ErrorNotification message={ errorMessage } title="Mapping could not be created." />}
      <Modal.Form
        cancelTitle="Go Back"
        className={ styles['mapping-form'] }
        proceedTitle={ step < 6 ? 'Save and Next' : 'Submit' }
        show={ isOpen }
        title={ STEP_TITLES[step] || '' }
        onCancel={ handleCancel }
      >
        {({ formId }) => (
          <Form
            categories={ categories }
            formId={ formId }
            otherLedgers={ otherLedgers }
            step={ step }
            taxes={ taxes }
            onSubmit={ handleSubmit }
          />
        )}
      </Modal.Form>
      {changeMapping && integration && (
        <changeMappingRevenueReportModal.Component
          integration={ integration }
          { ...changeMappingRevenueReportModal.props }
        />
      )}
    </>
  );
};

const useAddReportTypeModal = makeUseModal(AddReportTypeModal);

export {
  useAddReportTypeModal,
  AddReportTypeModal as default,
};
