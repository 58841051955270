import { object, string, number } from 'yup';

import { TID } from '@src/types/common';

interface ITaskItemFormValues{
    title: string;
    businessId: TID;
    description: string;
    status?: string;
    userId?: TID;
  }

const createTaskValidation = object({
  businessId:  number().required('Business Id is required.'),
  title:       string().required('Title is required.').max(100, 'Title must be less than 100 characters.'),
  description: string().required('Description is required.').max(255, 'Description must be less than 255 characters.'),
});
const updateTaskValidation = object({
  businessId:  number().required('Business Id is required.'),
  userId:      number().required('User Id is required'),
  title:       string().required('Title is required.').max(100, 'Title must be less than 100 characters.'),
  status:      string().required('Status is required.').max(100, 'Status must be less than 100 characters.'),
  description: string().required('Description is required.').max(255, 'Description must be less than 255 characters.'),
});

export { updateTaskValidation, ITaskItemFormValues, createTaskValidation };
