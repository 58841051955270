import { useQuery } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import { getReportByIdentifier, getReportItems, getItemLineItemDetails } from '@src/requests/report_service/report_items';
import { TMongoID } from '@src/types/common';
import { IReportItem } from '@src/types/report_service/report_item';
import { IReportServiceQBOLineItemDetails } from '@src/types/report_service/report_item_value_account';

const useGetReportItems = (reportId: TMongoID, endPoint: string) => {
  return useQuery<IReportItem[], Error>(
    [QueryKey.reportItems, reportId],
    () => getReportItems(reportId, endPoint),
    {
      enabled: Boolean(reportId),
    },
  );
};

const useGetReportByIdentifier = (reportId: string, reportIdentifier: string) => {
  return useQuery<IReportItem, Error>(
    [QueryKey.reportIdentifierItem, reportId, reportIdentifier],
    () => getReportByIdentifier(reportId, reportIdentifier),
    {
      enabled: Boolean(reportId) && Boolean(reportIdentifier),
    },
  );
};

const useGetLineItemDetails = (reportId: string, reportDataId: string, slug: string, itemId: string, columnId: string, chartOfAccountId: number, page: number) => {
  return useQuery<IReportServiceQBOLineItemDetails[], Error>(
    [QueryKey.itemLineItemDetails, reportId, reportDataId, slug, itemId, columnId, chartOfAccountId, page],
    () => getItemLineItemDetails(reportId, reportDataId, slug, itemId, columnId, chartOfAccountId, page),
    {
      enabled: Boolean(reportId) && Boolean(reportDataId) && Boolean(itemId) && Boolean(columnId) && Boolean(chartOfAccountId),
    },
  );
};

export {
  useGetReportItems,
  useGetReportByIdentifier,
  useGetLineItemDetails,
};
