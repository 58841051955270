import React from 'react';

import TableSection from '@src/components/ui_v2/table_section/table_section';

import Table from './table';
import { ITasksCollection } from '../hooks';
import { TTaskManagementTabs } from '../types';

import styles from './styles.module.scss';

interface IOpenTasksProps {
  tab: TTaskManagementTabs,
  collection :ITasksCollection;
}
const TasksList = ({
  tab,
  collection,
}: IOpenTasksProps) => {
  return (
    <TableSection className={ styles['task-table-section'] }>
      <Table
        collection={ collection }
        tab={ tab }
      />
    </TableSection>
  );
};
export default TasksList;
