import React, { useCallback, useEffect, useMemo } from 'react';

import { GroupBase } from 'react-select';
import { LoadOptions } from 'react-select-async-paginate';

import { useGetBusinessQuery } from '@src/hooks/queries/businesses';
import { getBusinesses } from '@src/requests/businesses';
import { TID } from '@src/types/common';

import { AsyncPaginateIconSelectInput, TIconOption, TOption } from '@src/components/ui_v2/inputs';
import { ReactSelectDropdownIndicator } from '@src/components/ui_v2/inputs/react_select/helpers';

import { formatSelectOption } from './user_icon_select_option';

import styles from '../styles.module.scss';

interface AsyncSelectTaskProps {
  selectedBusiness: TOption | null;
  setSelectedBusiness: (val: TOption) => void;
  businessId?: TID;
}
const TaskBusinessSelect = ({ businessId, selectedBusiness, setSelectedBusiness }: AsyncSelectTaskProps) => {
  const perPage = 20;
  const businessQuery = useGetBusinessQuery(Number(businessId));
  const currentBusiness = useMemo(() => {
    return businessQuery.data;
  }, [businessQuery.data]);
  useEffect(() => {
    if (businessId && !selectedBusiness && currentBusiness) {
      setSelectedBusiness({
        label: currentBusiness?.name,
        value: String(currentBusiness?.id),
      });
    }
  }, [businessId, currentBusiness, selectedBusiness, setSelectedBusiness]);
  const handleSource: LoadOptions<TIconOption, GroupBase<TIconOption>, any> = useCallback(
    (_, options, { page }) => {
      return getBusinesses({ page, perPage }).then((data) => {
        const hasMore = options.length + data.collection.length < data.meta.totalCount;
        const newOptions = data.collection.map((managementGroup) => ({
          label: managementGroup.name,
          value: String(managementGroup.id),
        }));
        return {
          hasMore,
          options:    newOptions,
          additional: { page: page + 1 },
        };
      });
    },
    [],
  );
  const handleChange = useCallback(
    (e) => {
      setSelectedBusiness(e);
    },
    [setSelectedBusiness],
  );
  return (
    <AsyncPaginateIconSelectInput
      defaultOptions
      additional={ { page: 1 } }
      className={ styles['task-select-input'] }
      classNamePrefix="custom-prefix"
      components={ {
        DropdownIndicator: ReactSelectDropdownIndicator,
      } }
      debounceTimeout={ 300 }
      formatOptionLabel={ formatSelectOption }
      isClearable={ false }
      isSearchable={ false }
      loadOptions={ handleSource }
      value={ selectedBusiness }
      onChange={ handleChange }
    />
  );
};
export default TaskBusinessSelect;
