import React from 'react';

import { useQuery } from 'react-query';
import { SingleValue } from 'react-select';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { useGetAccountingClasses } from '@src/hooks/queries/accounting_classes';
import {
  getBusinessChartOfAccounts,
  IGetBusinessChartOfAccountsParams,
} from '@src/requests/business_chart_of_accounts';

import Table from '@src/components/ui/table';
import { Button } from '@src/components/ui_v2/buttons';
import SelectOnTable from '@src/components/ui_v2/form/fields/select_on_table';

import {
  HandleChange,
  HandleSelectChange,
  RowData,
  SelectOption,
} from './schema';

import styles from './styles.module.scss';

interface EditableTableItemProps {
  itemData: RowData;
  index: number;
  handleChange: HandleChange;
  handleSelectChange: HandleSelectChange;
  deleteRow: (index: number) => void;
}

const journalEntryPositionOptions: SelectOption[] = [
  { value: 'Debit', label: 'Debit' },
  { value: 'Credit', label: 'Credit' },
];

const EditableTableItem: React.FC<EditableTableItemProps> = ({
  itemData,
  handleChange,
  handleSelectChange,
  index,
  deleteRow,
}) => {
  const business = useBusinessContext();

  const queryParams: IGetBusinessChartOfAccountsParams | null = business.id
    ? { businessId: business.id, simple: true }
    : null;

  const {
    data: chartOfAccountsData,
    isLoading: isChartLoading,
    error: chartError,
  } = useQuery(
    ['chartOfAccounts', business.id],
    () => (queryParams
      ? getBusinessChartOfAccounts(queryParams)
      : Promise.resolve({ collection: [] })),
    {
      staleTime: 1000 * 60 * 5,
      retry:     2,
      enabled:   !!queryParams,
    },
  );

  const {
    data: departmentData,
    isLoading: isDepartmentLoading,
    error: departmentError,
  } = useGetAccountingClasses(queryParams ?? {});

  const chartOfAccountsOptions: SelectOption[] =
    chartOfAccountsData?.collection?.map((account) => ({
      value: account.id,
      label: account.name,
    })) || [];

  const departmentOptions: SelectOption[] =
    departmentData?.collection?.map((account) => ({
      value: account.id,
      label: account.name,
    })) || [];

  return (
    <Table.Row>
      <Table.Cell pointer>
        <div className={ styles['input-area'] }>
          <input
            placeholder="Enter Items/Services"
            type="text"
            value={ itemData.itemsServices }
            onChange={ (e) => handleChange(index, 'itemsServices', e.target.value) }
          />
        </div>
      </Table.Cell>
      <Table.Cell pointer>
        {isDepartmentLoading && <p className={ styles['loading-select'] }>Loading...</p>}
        {departmentError && !isDepartmentLoading && (
          <p style={ { color: 'red' } }>
            Error:
            {' '}
            {departmentError.message}
          </p>
        )}
        {!isDepartmentLoading && !departmentError && (
          <SelectOnTable
            options={ departmentOptions }
            placeholder="-- Select Department --"
            value={ departmentOptions.find(
              (opt) => opt.value === itemData.departments,
            ) }
            onChange={ (option: SingleValue<SelectOption>) => handleSelectChange(index, 'departments', option) }
          />
        )}
      </Table.Cell>
      <Table.Cell pointer>
        {isChartLoading && <p className={ styles['loading-select'] }>Loading...</p>}
        {chartError && !isChartLoading && (
          <p style={ { color: 'red' } }>
            Error:
            {' '}
            {chartError}
          </p>
        )}
        {!isChartLoading && !chartError && (
          <SelectOnTable
            options={ chartOfAccountsOptions }
            placeholder="-- Select Chart of Accounts --"
            value={ chartOfAccountsOptions.find(
              (opt) => opt.value === itemData.chartOfAccounts,
            ) }
            onChange={ (option: SingleValue<SelectOption>) => handleSelectChange(index, 'chartOfAccounts', option) }
          />
        )}
      </Table.Cell>

      <Table.Cell pointer>
        <SelectOnTable
          options={ journalEntryPositionOptions }
          placeholder="-- Select Position --"
          value={ journalEntryPositionOptions.find(
            (opt) => opt.value === itemData.journalEntryPosition,
          ) }
          onChange={ (option: SingleValue<SelectOption>) => handleSelectChange(index, 'journalEntryPosition', option) }
        />
      </Table.Cell>
      <Table.Cell pointer>
        <Button
          className={ styles['delete-button'] }
          variant="link"
          onClick={ () => deleteRow(index) }
        >
          <i className="icon icon-trashcan" />
        </Button>
      </Table.Cell>
    </Table.Row>
  );
};

export default React.memo(EditableTableItem);
