import React, { useCallback } from 'react';

import Dropdown from '@src/components/ui_v2/dropdown';
import SpinnerIcon from '@src/components/utils/fa_icons/spinner_icon';
import { CloseIcon } from '@src/components/utils/icomoon';

import InnerInput from './inner_input';
import Item from './item';
import SearchInput from '../../search_dropdown/search_input';
import { TOption } from '../types';

import styles from '../styles.module.scss';

interface IMultiItemSelectProps extends
  Omit<React.ComponentPropsWithoutRef<typeof InnerInput>, 'onClear'> {
    options: TOption[],
    value: TOption[],
    addItem?: boolean,
    placeholder?: string,
    disabled?: boolean,
    menuClassName?: string,
    hideSelectAllOption?: boolean,
    onChange: (value: TOption[]) => void,
    handleScroll?: (e: React.UIEvent<HTMLDivElement>) => void,
    showLoader?: boolean,
    searchInputPlaceholder?: string,
    searchValue?: string,
    setSearchValue?: (value: string) => void,
  }

const allSelect: TOption = {
  label: 'Select all',
  value: 'all',
};

const MultiSelectAddItem = ({
  placeholder = 'Select',
  options,
  value,
  addItem = false,
  hideSelectAllOption = false,
  disabled,
  menuClassName,
  onChange,
  handleScroll,
  showLoader = false,
  searchInputPlaceholder = 'Search',
  searchValue,
  setSearchValue,
  ...props
}: IMultiItemSelectProps) => {
  const handleSelect = useCallback((checked: boolean, optionValue: TOption) => {
    const newValue = checked
      ? [...value, optionValue]
      : value.filter((val: TOption) => val.value !== optionValue.value);
    onChange(newValue);
  }, [value, onChange]);

  const handleAllSelect = useCallback((checked: boolean) => {
    if (checked) {
      onChange(options);
    } else {
      onChange([]);
    }
  }, [onChange, options]);

  const handleClear = useCallback(() => {
    onChange([]);
  }, [onChange]);

  return (
    <>
      <Dropdown.Provider className={ styles['dropdown-input'] }>
        <InnerInput
          { ...props }
          disabled={ disabled }
          placeholder={ placeholder }
          value={ value }
          onClear={ handleClear }
        />
        <Dropdown.Menu className={ menuClassName } onScroll={ handleScroll }>
          {setSearchValue && (
            <div className={ styles['search-input-container'] }>
              <SearchInput
                placeholder={ searchInputPlaceholder }
                value={ searchValue }
                onChange={ setSearchValue }
              />
            </div>
          )}
          {!hideSelectAllOption && (
            <Item
              key={ allSelect.value }
              disabled={ disabled }
              option={ allSelect }
              selected={ value.length === options.length ? [allSelect] : [] }
              onSelect={ handleAllSelect }
            />
          )}
          {
            options.map((option: TOption) => (
              <Item
                key={ option.value }
                disabled={ disabled }
                option={ option }
                selected={ value }
                onSelect={ handleSelect }
              />
            ))
          }
          {
            searchValue && options.length === 0 && (
              <div className={ styles['spinner-container'] }>
                <span>No user found</span>
              </div>
            )
          }
          { !!handleScroll && showLoader && (
            <div className={ styles['spinner-container'] }>
              <SpinnerIcon spin size="2x" />
            </div>
          )}
        </Dropdown.Menu>
      </Dropdown.Provider>

      {addItem && value.length !== 0 && (
        <div className={ styles['multi-item-select-content'] }>
          {value.map((opt: TOption) => (
            <div key={ opt.value } className={ styles.item }>
              <p>{ opt.label }</p>

              { !disabled && (
                <CloseIcon
                  pointer
                  fontSize={ 23 }
                  inColor="grey-1050"
                  onClick={ () => {
                    onChange(value.filter((val: TOption) => val.value !== opt.value));
                  } }
                />
              ) }
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default React.memo(MultiSelectAddItem);
