import AmountField from './fields/amount_field';
import DateField from './fields/date_field';
import DateRangeField from './fields/date_range_field';
import DropdownField from './fields/dropdown_field';
import FileField from './fields/file_field';
import NumberField from './fields/number_field';
import PercentageField from './fields/percentage_field';
import RadioField from './fields/radio_field';
import SelectOnTable from './fields/select_on_table';
import StaticField from './fields/static_field';
import TextAreaField from './fields/text_area_field';
import TextField from './fields/text_field';
import Form from './form';

export default Object.assign(Form, {
  AmountField,
  DateField,
  DateRangeField,
  NumberField,
  PercentageField,
  StaticField,
  TextField,
  TextAreaField,
  DropdownField,
  RadioField,
  FileField,
  SelectOnTable,
});
