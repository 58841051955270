import React, { useCallback, useEffect, useState } from 'react';

import { ITaskServiceDocument } from '@src/types/task_service_documents';

import { AssignedTasksTable } from './assigned';
import { BlockedTasksTable } from './blocked';
import { ClosedTasksTable } from './closed';
import { OpenTasksTable } from './open';
import { TASK_TABS } from '../components/helper/constants';
import { ITasksCollection } from '../hooks';
import { TTaskManagementTabs } from '../types';

const TasksTable = ({ collection, tab }:
  { tab:TTaskManagementTabs, collection: ITasksCollection}) => {
  const [activeTab, setActiveTab] = useState(tab);
  useEffect(() => {
    setActiveTab(tab);
  }, [tab]);
  const onRowClick = useCallback((rowData:ITaskServiceDocument) => {
    Backbone.history.navigate(
      `/task_management/${rowData.taskId}`,
      { trigger: true },
    );
  }, []);
  const renderTabContent = useCallback(() => {
    switch (activeTab) {
      case TASK_TABS.OPEN:
        return (
          <OpenTasksTable
            collection={ collection }
            onRowClick={ onRowClick }
          />
        );
      case TASK_TABS.ASSIGNED:
        return (
          <AssignedTasksTable collection={ collection } onRowClick={ onRowClick } />
        );
      case TASK_TABS.CLOSED:
        return (
          <ClosedTasksTable collection={ collection } onRowClick={ onRowClick } />
        );
      case TASK_TABS.BLOCKED:
        return (
          <BlockedTasksTable collection={ collection } onRowClick={ onRowClick } />
        );
      default:
        return (
          null
        );
    }
  }, [activeTab, collection, onRowClick]);
  return renderTabContent();
};
export default TasksTable;
