import React, { useCallback, useMemo, useState } from 'react';

import toastr from '@lib/toastr';
import { useRunAuditScan } from '@src/hooks/queries/operational_dashboard/audit_scan';
import { TBooksGptOnboardingState, TBusinessIntent, TFirmSuiteOnboardingState } from '@src/types/businesses';
import { TID } from '@src/types/common';
import { AuditScanDetails } from '@src/types/operational_dashboard/audit_scan';

import { useConfirmAuditModal } from '@src/components/operational_dashboard/components/confirm_audit_modal';
import { businessCellActionButton, isInSetupStatus } from '@src/components/operational_dashboard/helper/functional';
import { IBusinessCommonProps } from '@src/components/operational_dashboard/types';
import { AppLink } from '@src/components/ui_v2/buttons';
import { BusinessDefaultAvatarIcon } from '@src/components/utils/icomoon';
import ImgIcon from '@src/components/utils/img_icons/img_icon';

import ActivationButton from './business_cell_action_btn/activate_business_button';
import CompleteSetupButton from './business_cell_action_btn/complete_setup_button';
import ProgressActionLoader from './business_cell_action_btn/progress_action_loader';
import ReRunAuditScanButton from './business_cell_action_btn/re_run_audit_scan';
import AuditScanButton from './business_cell_action_btn/run_audit_scan';
import StartLearningButton from './business_cell_action_btn/start_learning_button';

import styles from './style.module.scss';

type IBusinessCellProps = IBusinessCommonProps & {
  auditScan?: AuditScanDetails | null | undefined;
}

const BusinessCell = ({
  displayName,
  legalName,
  icon,
  setupStatus,
  businessId,
  firmSuiteOnboardingState,
  booksGptOnboardingState,
  isSetupCompleted,
  auditScan,
  businessIntent,
}: IBusinessCellProps) => {
  const { mutate: runAuditScan } = useRunAuditScan();
  const [isQboConnected, setIsQboConnected] = useState(false);

  const {
    Component: ConfirmAuditModal,
    open: openConfirmAuditModal,
    props: confirmAuditModalProps,
  } = useConfirmAuditModal({
    onDone: () => {
      runAuditScan({
        businessId: businessId as TID,
      }, {
        onError: (error) => {
          toastr.error(error.message, 'Error');
        },
      });
    },
  });

  const actionButton = useCallback(() => {
    if (!businessId) return null;
    const buttonState = businessCellActionButton({
      firmSuiteOnboardingState: firmSuiteOnboardingState as TFirmSuiteOnboardingState,
      booksGptOnboardingState:  booksGptOnboardingState as TBooksGptOnboardingState,
      isSetupCompleted:         Boolean(isSetupCompleted),
      businessIntent:           businessIntent as TBusinessIntent,
      auditScan:                auditScan as AuditScanDetails,
    });
    switch (buttonState) {
      case 'activate':
        return (
          <ActivationButton
            businessId={ businessId }
            displayName={ displayName }
            setIsQboConnected={ setIsQboConnected }
          />
        );
      case 'complete_setup':
        return <CompleteSetupButton businessId={ businessId } />;
      case 'in_ledger_setup':
        return <ProgressActionLoader status="in_ledger_setup" />;
      case 'ledger_setup_failed':
        return <StartLearningButton businessId={ businessId } />;
      case 'run_audit_scan':
        return <AuditScanButton status={ auditScan?.status } onRunAudit={ openConfirmAuditModal } />;
      case 'run_audit_scan_in_progress':
        return <ProgressActionLoader status="in_progress" />;
      case 'run_audit_scan_pending':
        return <ProgressActionLoader status="pending" />;
      case 'run_audit_scan_failed':
        return <ReRunAuditScanButton status={ auditScan?.status } onRunAudit={ openConfirmAuditModal } />;
      case 'run_audit_scan_broken':
        return <ReRunAuditScanButton status={ auditScan?.status } onRunAudit={ openConfirmAuditModal } />;
      default:
        return null;
    }
  }, [
    firmSuiteOnboardingState,
    booksGptOnboardingState,
    isSetupCompleted,
    auditScan,
    openConfirmAuditModal,
    businessId,
    displayName,
    businessIntent,
  ]);

  const renderBusinessInfo = useCallback(() => {
    return businessIntent === 'FullBookkeeping' ? (
      <AppLink href={ `/businesses/${businessId}` }>
        <h3 className={ styles['business-name'] }>{displayName}</h3>
        <p className={ styles['business-type'] }>{legalName}</p>
      </AppLink>
    ) : (
      <>
        <h3 className={ styles['business-name'] }>{displayName}</h3>
        <p className={ styles['business-type'] }>{legalName}</p>
      </>
    );
  }, [displayName, legalName, businessId, businessIntent]);

  const isReRunAuditScan = useMemo(() => auditScan?.status === 'completed'
  || auditScan?.status === 'failed'
  || auditScan?.status === 'broken', [auditScan?.status]);

  return (
    <div className={ styles['business-column-container'] }>
      <ConfirmAuditModal
        { ...confirmAuditModalProps }
        isRunAuditEnabled={ !isReRunAuditScan }
        title={ isReRunAuditScan ? 'Re-run Audit Scan' : 'Run Audit Scan' }
      />
      <div className={ styles['business-column-info'] }>
        {isInSetupStatus(setupStatus) && <span className={ styles['in-setup-tag'] }>IN SETUP</span>}
        {icon ? (
          <div className={ styles['business-icon-container'] }>
            <ImgIcon
              alt="Business Icon"
              className={ styles['business-icon'] }
              src={ icon }
            />
          </div>
        )
          : <BusinessDefaultAvatarIcon className={ styles['business-icon-default'] } fontSize={ 32 } />}
        <div className={ styles['business-content'] }>
          {renderBusinessInfo()}
        </div>
      </div>
      <div className={ styles['business-column-action'] }>
        { isQboConnected ? <CompleteSetupButton businessId={ businessId ?? 0 } /> : actionButton() }
      </div>
    </div>
  );
};
export default BusinessCell;
