import React, { useState } from 'react';

import { SingleValue } from 'react-select';

import { Button } from '@src/components/ui_v2/buttons';

import Table from './editable_table';
import { RowData, SelectOption } from './schema';

import styles from './styles.module.scss';

interface IEditableTableProps {
  title: string;
}

const EditableTable = ({ title }: IEditableTableProps) => {
  const [rows, setRows] = useState<RowData[]>([
    {
      itemsServices:        '',
      departments:          '',
      chartOfAccounts:      '',
      journalEntryPosition: '',
    },
  ]);

  const [sortDirection, setSortDirection] = useState<'asc' | 'desc' | undefined>(undefined);
  const [sortColumn, setSortColumn] = useState<keyof RowData | undefined>(undefined);

  const handleSort = (column: keyof RowData) => {
    const isAscending = sortColumn === column && sortDirection === 'asc';
    setSortColumn(column);
    setSortDirection(isAscending ? 'desc' : 'asc');

    const sortedRows = [...rows].sort((a, b) => {
      const valueA = a[column].toString().toLowerCase();
      const valueB = b[column].toString().toLowerCase();

      if (valueA < valueB) return isAscending ? 1 : -1;
      if (valueA > valueB) return isAscending ? -1 : 1;
      return 0;
    });

    setRows(sortedRows);
  };

  const clearSorting = () => {
    setSortColumn(undefined);
    setSortDirection(undefined);
    setRows([...rows]);
  };

  const handleChange = (index: number, field: keyof RowData, value: string): void => {
    const updatedRows = [...rows];
    updatedRows[index][field] = value;
    setRows(updatedRows);
  };

  const handleSelectChange = (
    index: number,
    field: keyof RowData,
    selectedOption: SingleValue<SelectOption>,
  ): void => {
    const updatedRows = [...rows];
    updatedRows[index][field] = selectedOption ? String(selectedOption.value) : '';
    setRows(updatedRows);
  };

  const addNewRow = (): void => {
    setRows((prevRows) => [
      ...prevRows,
      {
        itemsServices:        '',
        departments:          '',
        chartOfAccounts:      '',
        journalEntryPosition: '',
      },
    ]);
  };

  const deleteRow = (rowIndex: number) => {
    const updatedRows = rows.filter((_, index) => index !== rowIndex);
    setRows(updatedRows);
  };

  return (
    <div className={ styles['editable-table-container'] }>
      <h1>
        <b>{title}</b>
      </h1>
      <br />
      <Table
        deleteRow={ deleteRow }
        handleChange={ handleChange }
        handleSelectChange={ handleSelectChange }
        rowData={ rows }
        sortColumn={ sortColumn }
        sortDirection={ sortDirection }
        sorting={ {
          data:                   { orderColumn: sortColumn, orderDirection: sortDirection },
          getColumnSortDirection: (column) => (column === sortColumn ? sortDirection : undefined),
          toggleColumSort:        handleSort,
          sortColum:              (column: keyof RowData, direction: 'asc' | 'desc') => {
            setSortColumn(column);
            setSortDirection(direction);
            handleSort(column);
          },
          clear: clearSorting,
        } }
      />

      <Button
        className={ styles['add-button'] }
        variant="link"
        onClick={ addNewRow }
      >
        Add New Item/Service
      </Button>
    </div>
  );
};

export default EditableTable;
