import React, { useCallback } from 'react';

import TextAreaInput from '@src/components/ui_v2/inputs/text_area_input';

import styles from './document_list.module.scss';

interface NotesSectionProps {
  note: string;
  isReviewed: boolean;
  onNoteChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

const NotesSection: React.FC<NotesSectionProps> = ({
  note,
  isReviewed,
  onNoteChange,
}) => {
  const renderNoteContent = useCallback(() => {
    if (!isReviewed) {
      return (
        <TextAreaInput
          className={ styles['note-input'] }
          placeholder="Add note"
          value={ note }
          onChange={ onNoteChange }
        />
      );
    }

    if (note) {
      return <div className={ styles['note-text'] }>{ note }</div>;
    }

    return (
      <div className={ styles['no-notes-message'] }>
        No notes added
      </div>
    );
  }, [isReviewed, note, onNoteChange]);

  return (
    <section>
      <div className={ styles['section-header'] }>
        <h1 className={ styles['notes-section-header'] }>Notes</h1>
      </div>
      {renderNoteContent()}
    </section>
  );
};

export default NotesSection;
