import React, { useMemo } from 'react';

import { useGetDefaultAccountingFirm } from '@src/hooks/queries/signup';
import { IBusiness } from '@src/types/businesses';

import ConnectLedgerForm from './form';
import OnboardingPage from '../layout/onboarding_page';

const QBO_CONNECT_TITLE = 'Connect Your QuickBooks Accountant Account';

const ConnectLedgerView = () => {
  const query = useGetDefaultAccountingFirm();
  const business = useMemo(() => query?.data as IBusiness || {}, [query]);
  
  return (
    <OnboardingPage isHideBorder title={ QBO_CONNECT_TITLE }>
      <ConnectLedgerForm />
    </OnboardingPage>
  );
};

export default React.memo(ConnectLedgerView);
