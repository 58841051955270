import React from 'react';

import { ISorting } from '@src/types/sorting';
import { TTasksDocumentsSortColumn } from '@src/types/task_service_documents';

import Table from '@src/components/ui_v2/table';

import styles from '../styles.module.scss';

interface IDashboardTableHeadProps {
    sorting:ISorting<TTasksDocumentsSortColumn>
}

const TableHead = ({ sorting }:IDashboardTableHeadProps) => {
  return (
    <Table.Head>
      <Table.Row className={ styles['task-table-head'] }>
        <Table.HCell<TTasksDocumentsSortColumn>
          columnName="taskId"
          sorting={ sorting }
          width="10%"
        >
          Task ID
        </Table.HCell>
        <Table.HCell width="35%">
          Title
        </Table.HCell>
        <Table.HCell width="15%">
          Business
        </Table.HCell>
        <Table.HCell<TTasksDocumentsSortColumn>
          columnName="userName"
          sorting={ sorting }
          width="20%"
        >
          Assignee
        </Table.HCell>
        <Table.HCell<TTasksDocumentsSortColumn>
          columnName="status"
          sorting={ sorting }
          width="10%"
        >
          Status
        </Table.HCell>
        <Table.HCell<TTasksDocumentsSortColumn>
          columnName="createdAt"
          sorting={ sorting }
          width="10%"
        >
          Open Since
        </Table.HCell>
      </Table.Row>
    </Table.Head>
  );
};
export default TableHead;
