import React from 'react';

import Root from '@src/components/root';

import AiChatBotRouter from './ai_chat_bot_router';
import SendbirdWrapper from './components/sendbird_wrapper';
import { ScrollProvider } from './provider/ai_chat_bot_scroll_provider';
import { MessageListenerProvider } from './provider/message_listener_provider';

const AiChatBotPage = (): JSX.Element => {
  return (
    <Root>
      <SendbirdWrapper>
        <ScrollProvider>
          <MessageListenerProvider>
            <AiChatBotRouter />
          </MessageListenerProvider>
        </ScrollProvider>
      </SendbirdWrapper>
    </Root>
  );
};

export default AiChatBotPage;
