import React, { useCallback } from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { useUpdateDashboardUsers } from '@src/hooks/queries/dashboards/dashboard_users';
import { useLDBusinessFeatureQuery, useLDUserFeatureQuery } from '@src/hooks/queries/launch_darkly_features';
import { IBusinessServicesInfo } from '@src/types/business_services_info';
import { IBusinessUser } from '@src/types/business_users';
import { TMongoID } from '@src/types/common';
import { IDashboard } from '@src/types/dashboards/dashboards';

import MutationStatus from '@src/components/utils/mutation_status';

import DashboardsSidemenu from '../sidemenu';
import WidgetContainer from '../widgets/container';
import DashboardHeader from './dashboard_header';
import DashboardNoAccessView from './dashboard_no_access_view';
import DashboardSidebarMenuItems from './dashboard_sidebar_menu_items';

import styles from './styles.module.scss';

interface IDashboardMainPageProps {
  dashboards: IDashboard[],
  currentDashboard: IDashboard,
  widgetId: TMongoID | null,
  businessServiceInfo?: IBusinessServicesInfo,
}

const DashboardMainPage = ({
  dashboards,
  currentDashboard,
  widgetId,
  businessServiceInfo,
}: IDashboardMainPageProps) => {
  const business = useBusinessContext();
  const updateUsers = useUpdateDashboardUsers();
  const { data: globalNavigationBusinessEnabled } = useLDBusinessFeatureQuery(business.id, window.Docyt.Common.Constants.GLOBAL_NAVIGATION);
  const { data: globalNavigationUserEnabled } = useLDUserFeatureQuery(window.Docyt.currentAdvisor.id, window.Docyt.Common.Constants.GLOBAL_NAVIGATION);

  const { mutate: updateDashboardUsers } = updateUsers;

  const handleShareDone = useCallback((accessibleUsers: IBusinessUser[]) => {
    updateDashboardUsers(
      {
        dashboardId: currentDashboard.id,
        userIds:     accessibleUsers.map((user) => user.id),
      },
      {
        onSuccess: () => {
          currentDashboard.dashboardUserIds = accessibleUsers.map((user) => user.id);
          window.Docyt.vent.trigger('dashboards:information:updated');
        },
      },
    );
  }, [currentDashboard, updateDashboardUsers]);

  const isGlobalNavigationEnabled: boolean = !!((globalNavigationUserEnabled || globalNavigationBusinessEnabled));
  const isGlobalNavigationNewUi: boolean = isGlobalNavigationEnabled && !business.isAccountantMember;
  const canAccessDashboard: boolean = !!(currentDashboard.hasAccess && (businessServiceInfo?.canAccessDashboardService || businessServiceInfo?.isBusinessAdmin));

  return (
    <>
      <MutationStatus
        mutation={ updateUsers }
        successMessage="Updated current dashboard users successfully"
      />
      {canAccessDashboard && (
        isGlobalNavigationNewUi ? (
          <div id="sidemenu-region">
            <DashboardSidebarMenuItems businessId={ business.id } />
          </div>
        ) : (
          <DashboardsSidemenu
            businessServiceInfo={ businessServiceInfo }
            currentDashboard={ currentDashboard }
            dashboards={ dashboards }
            widgetId={ widgetId }
          />
        )
      )}
      <div className={ styles['detail-container'] }>
        <DashboardHeader
          businessServiceInfo={ businessServiceInfo }
          currentDashboard={ currentDashboard }
          onShareDone={ handleShareDone }
          showToggleButton={ !widgetId }
        />
        {
          canAccessDashboard
            ? (
              <WidgetContainer
                currentDashboard={ currentDashboard }
                standardCategoryId={ businessServiceInfo?.standardCategoryId || -1 }
                widgetId={ widgetId }
              />
            )
            : (
              <DashboardNoAccessView />
            )
        }
      </div>
    </>
  );
};

export default DashboardMainPage;
