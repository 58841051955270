import React from 'react';

import Select from 'react-select';

interface SelectOption {
  value: string | number;
  label: string;
}

interface SelectOnTableProps {
  options: SelectOption[];
  placeholder: string;
  value?: SelectOption;
  onChange: (option: SelectOption | null) => void;
}

const SelectOnTableStyles = {
  control: (base: any) => ({
    ...base,
    background:   '#eceded',
    border:       'none',
    boxShadow:    'none',
    cursor:       'pointer',
    borderRadius: '10px',
    minHeight:    '40px',
  }),
  menu: (base: any) => ({
    ...base,
    zIndex: 9999999,
  }),
  menuPortal: (base: any) => ({
    ...base,
    zIndex: 9999999,
  }),
  option: (base: any, state: any) => ({
    ...base,
    backgroundColor: state.isFocused ? '#f0f0f0' : '#fff',
    color:           '#333',
    cursor:          'pointer',
  }),
};

const SelectOnTable: React.FC<SelectOnTableProps> = ({
  options,
  placeholder,
  value,
  onChange,
}) => {
  return (
    <Select
      components={ {
        DropdownIndicator:  () => null,
        IndicatorSeparator: () => null,
      } }
      menuPortalTarget={ document.body }
      options={ options }
      placeholder={ placeholder }
      styles={ SelectOnTableStyles }
      value={ value }
      onChange={ onChange }
    />
  );
};

export default React.memo(SelectOnTable);
