import React from 'react';

import classNames from 'classnames';

import toastr from '@lib/toastr';
import { useAssignTaskServiceDocument } from '@src/hooks/queries/task_service_documents';
import { ITaskServiceDocument } from '@src/types/task_service_documents';
import { getDateGap } from '@src/utils/date_helpers';

import Assignee from '@src/components/task_management/components/assignee';
import TaskDateField from '@src/components/task_management/components/task_date_field';
import TaskOpenSince from '@src/components/task_management/components/task_open_since';
import { UserAvatar } from '@src/components/ui/avatars';
import { TIconOption } from '@src/components/ui/form';
import Table from '@src/components/ui_v2/table';

import { ITaskIconOption } from '../../types';

import styles from '../styles.module.scss';

interface IDashboardTableBodyProps {
    records:ITaskServiceDocument[];
    onRowClick:(rowData : ITaskServiceDocument)=>void;
}
const TableBody = ({ records, onRowClick }:IDashboardTableBodyProps) => {
  const classes = classNames(styles['task-default-row'], styles['red-row']);
  const assignTask = useAssignTaskServiceDocument();
  const handleAssigneeChange = (taskId:string, user?:ITaskIconOption | TIconOption | null) => {
    if (user?.value && taskId) {
      assignTask.mutate({ formData: { userId: user?.value }, taskId }, { onSuccess: () => {
        toastr.success(
          'Assign Task Successfully',
          'Assign Task',
        );
      },
      onError: (error) => {
        toastr.error(
          error.message,
          'Error Assign Task',
        );
      } });
    }
  };
  return (
    <Table.Body>
      {records?.map((columnData:ITaskServiceDocument) => (
        <Table.Row
          key={ columnData.taskId }
          className={ getDateGap(columnData.createdAt).isGreaterOrEqualTwoDays
            ? classes : styles['task-default-row'] }
        >
          <Table.Cell onClick={ () => onRowClick(columnData) }>
            <div className={ styles['row-text-values'] }>
              #
              {columnData.taskId}
            </div>
          </Table.Cell>
          <Table.Cell onClick={ () => onRowClick(columnData) }>
            <div className={ styles['row-text-values'] }>
              {columnData.title}
            </div>
          </Table.Cell>
          <Table.Cell>
            <div className={ styles['row-text-values'] }>
              {columnData.businessName}
            </div>
          </Table.Cell>
          <Table.Cell>
            <TaskDateField disabled value={ columnData.createdAt } />
          </Table.Cell>
          <Table.Cell onClick={ () => onRowClick(columnData) }>
            <div className={ styles['row-text-values'] }>
              <TaskOpenSince date={ columnData?.createdAt } />
            </div>
          </Table.Cell>
          <Table.IconCell>
            <Assignee
              businessId={ columnData?.businessId }
              toggleElement={ <UserAvatar inColor="grey-800" /> }
              onChange={ (newValue) => {
                handleAssigneeChange(columnData.taskId, newValue);
              } }
            />
          </Table.IconCell>
        </Table.Row>
      ))}
    </Table.Body>
  );
};
export default TableBody;
