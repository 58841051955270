import React from 'react';

import { ISorting } from '@src/types/sorting';

import Table from '@src/components/ui/table';
import SortIndicator from '@src/components/utils/sort_indicator';

import Item from './editable_table_item';
import { RowData, HandleChange, HandleSelectChange } from './schema';

import styles from './styles.module.scss';

interface IEditableTableProps {
  rowData: RowData[];
  handleChange: HandleChange;
  handleSelectChange: HandleSelectChange;
  deleteRow: (index: number) => void;
  sorting: ISorting<keyof RowData>;
  sortColumn: keyof RowData | undefined;
  sortDirection: string | undefined;
}

const EditableTable = ({
  rowData,
  handleChange,
  handleSelectChange,
  deleteRow,
  sorting,
  sortColumn,
  sortDirection,
}: IEditableTableProps) => {
  return (
    <Table
      className={ `mapping-revenue-category-table ${styles['editable-table']}` }
      wrapperClassName="mapping-revenue-category-table-table-wrapper"
    >
      <Table.Head>
        <Table.Row>
          <Table.HCell<keyof RowData> columnName="itemsServices" sorting={ sorting } widthPercent={ 24 }>
            <div className={ styles['thead-short'] }>
              <span>Items/Services</span>
              <SortIndicator
                direction={
                  (sortColumn === 'itemsServices' ? sortDirection : undefined) as 'asc' | 'desc' | undefined
                }
              />
            </div>
          </Table.HCell>
          <Table.HCell<keyof RowData> columnName="departments" sorting={ sorting } widthPercent={ 24 }>
            <div className={ styles['thead-short'] }>
              <span>Departments</span>
              <SortIndicator
                direction={
                  (sortColumn === 'departments' ? sortDirection : undefined) as 'asc' | 'desc' | undefined
                }
              />
            </div>
          </Table.HCell>
          <Table.HCell<keyof RowData> columnName="chartOfAccounts" sorting={ sorting } widthPercent={ 24 }>
            <div className={ styles['thead-short'] }>
              <span>Chart of Accounts</span>
              <SortIndicator
                direction={
                  (sortColumn === 'chartOfAccounts' ? sortDirection : undefined) as 'asc' | 'desc' | undefined
                }
              />
            </div>
          </Table.HCell>
          <Table.HCell<keyof RowData> columnName="journalEntryPosition" sorting={ sorting } widthPercent={ 24 }>
            <div className={ styles['thead-short'] }>
              <span>Journal Entry Position</span>
              <SortIndicator
                direction={
                  (sortColumn === 'journalEntryPosition' ? sortDirection : undefined) as 'asc' | 'desc' | undefined
                }
              />
            </div>
          </Table.HCell>
          <Table.HCell<keyof RowData> widthPercent={ 3 } />
        </Table.Row>
      </Table.Head>
      <Table.Body>
        {rowData.map((itemData, index) => (
          <Item
            // eslint-disable-next-line react/no-array-index-key
            key={ index }
            deleteRow={ () => deleteRow(index) }
            handleChange={ handleChange }
            handleSelectChange={ handleSelectChange }
            index={ index }
            itemData={ itemData }
          />
        ))}
      </Table.Body>
    </Table>
  );
};

export default React.memo(EditableTable);
