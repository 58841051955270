import React, { memo } from 'react';

import DetailsRegion from '@src/components/ui_v2/layout/details_region/details_region';
import HeaderRegion from '@src/components/ui_v2/layout/header_region';
import Section from '@src/components/utils_v2/section';

import { useTaskServiceCollection } from '../hooks';
import TasksList from '../tasks_list/list';
import { TTaskManagementTabs } from '../types';

import styles from '../styles.module.scss';

interface ITaskManagementContainerProps {
  tab : TTaskManagementTabs;
  headerRegionChild?: React.ReactNode;
  headerTitle:string;
  status:string[];
}
const TaskManagementContainer = ({ tab, headerRegionChild, headerTitle, status } : ITaskManagementContainerProps) => {
  const collection = useTaskServiceCollection({ status, tab });
  return (
    <Section.Provider section={ collection.section }>
      <DetailsRegion>
        <HeaderRegion>
          {headerTitle && (
          <HeaderRegion.Title>
            <span className={ styles['table-header-label'] }>
              {headerTitle}
            </span>
          </HeaderRegion.Title>
          )}
          {headerRegionChild && (
          <HeaderRegion.ActionsArea>
            {headerRegionChild}
          </HeaderRegion.ActionsArea>
          )}
        </HeaderRegion>
        <TasksList collection={ collection } tab={ tab } />
      </DetailsRegion>
    </Section.Provider>
  );
};
export default memo(TaskManagementContainer);
