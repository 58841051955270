/* eslint-disable import/order */
import React, { memo, useCallback, useEffect, useState } from 'react';

import classNames from 'classnames';

import Dropdown from '@src/components/ui/dropdown';
import { TOption } from '@src/components/ui_v2/inputs';
import { CaretIcon } from '@src/components/utils/fa_icons';

import { defaultStatusOptions, statusLabels } from './helper/constants';
import { StatusKey } from '../types';

import styles from './styles.module.scss';

interface ITaskStatusSelectProps {
  selectedValue:StatusKey,
  onChange?:(option:TOption)=>void, statusOptions?:TOption[];
  className?:string;
  menuClassName?:string;
}
const TaskStatusSelect = ({ selectedValue,
  onChange,
  statusOptions,
  className,
  menuClassName }
  :ITaskStatusSelectProps) => {
  const [options, setOptions] = useState<TOption[]>(statusOptions ?? defaultStatusOptions);
  const [selectedOptionLabel, setSelectedOptionLabel] = useState<string>(statusLabels[selectedValue as StatusKey]);
  const handleOptionSelect = ({ label, value }:{label:string;value:string}) => {
    if (onChange) {
      onChange({ label, value });
    }
    setSelectedOptionLabel(label);
  };

  useEffect(() => {
    if (statusOptions) {
      setOptions(statusOptions ?? defaultStatusOptions);
    }
  }, [statusOptions]);

  useEffect(() => {
    if (onChange && options) {
      onChange(options.find((item) => item.value === selectedValue) as TOption);
    }
  }, [selectedValue, onChange, options]);

  const mainClass = classNames(
    'form-control',
    styles['task-status-select-conatiner'],
    className,
  );

  const menuClasses = classNames(styles['task-status-select-menu'], menuClassName);

  const handleStatusAction = useCallback((status:string) => {
    if (status === 'Pending') {
      return styles['task-status-select-label-red'];
    } if (status === 'In Progress') {
      return styles['task-status-select-label-green'];
    }
    return '';
  }, []);

  return (
    <Dropdown
      className="display-flex"
    >
      <Dropdown.LinkToggle noButton className="amount-select-menu-btn">
        <div className={ mainClass }>
          <span className={ handleStatusAction(selectedOptionLabel) }>
            { selectedOptionLabel }
          </span>
          <CaretIcon fontSize={ 14 } variant="down" />
        </div>
      </Dropdown.LinkToggle>
      <Dropdown.Menu pointer alignment="left" className={ menuClasses } fontSize={ 12 }>
        {
        options.map((option) => (
          <Dropdown.Item
            key={ option.value }
            defaultValue={ selectedValue }
            onSelect={ () => handleOptionSelect(option) }
          >
            { option.label }
          </Dropdown.Item>
        ))
      }
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default memo(TaskStatusSelect);
