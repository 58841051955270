import React from 'react';

import { ITaskServiceDocument } from '@src/types/task_service_documents';

import { ITasksCollection } from '@src/components/task_management/hooks';
import Table from '@src/components/ui_v2/table';

import TableBody from './table_body';
import TableHead from './table_head';

import style from '../styles.module.scss';

const AssignedTasksTable = ({ collection, onRowClick }:
  { collection: ITasksCollection, onRowClick:
     (rowData : ITaskServiceDocument) => void }) => {
  const {
    query: infiniteQuery,
    records,
    sorting,
  } = collection;

  return (
    <Table query={ infiniteQuery } wrapperClassName={ style['table-container'] }>
      <TableHead sorting={ sorting } />
      <TableBody
        records={ records }
        onRowClick={ onRowClick }
      />
    </Table>
  );
};

export default React.memo(AssignedTasksTable);
