import React, { memo } from 'react';

import classNames from 'classnames';

import { getDateGap } from '@src/utils/date_helpers';

import styles from './styles.module.scss';

const TaskOpenSince = (({ date }:{date:string | number | Date }) => {
  const classes = classNames(styles['open-since-label-red'], styles['task-open-since']);
  return (
    <div className={ getDateGap(date)?.isGreaterOrEqualTwoDays
      ? classes : styles['task-open-since'] }
    >
      {getDateGap(date).gap}
    </div>
  );
});
export default memo(TaskOpenSince);
