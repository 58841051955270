import React, { useCallback } from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { useLDBusinessFeatureQuery } from '@src/hooks/queries/launch_darkly_features';
import {
  IUpsertIntegrationResponse,
} from '@src/requests/integrations/integrations';
import { revenueServiceMappingPath } from '@src/routes';
import { TID } from '@src/types/common';

import Modal from '@src/components/ui/modal';
import { Button } from '@src/components/ui_v2/buttons';

import styles from './styles.module.scss';

interface IpullIntegrationSuccessModalProps extends IUseModalProps {
  UpsertIntegrationResponse?: IUpsertIntegrationResponse | null,
  revenueReportTypeId?: TID | null | undefined,
}

const PullIntegrationSuccessModal = ({
  isOpen,
  onDone,
  onCancel,
  UpsertIntegrationResponse,
  revenueReportTypeId,
}: IpullIntegrationSuccessModalProps) => {
  const business = useBusinessContext();

  const featureQuery = useLDBusinessFeatureQuery(
    business.id,
    window.Docyt.Common.Constants.REVENUE_CENTER_NEW_REVENUE_MAPPING,
  );

  const handleOnClick = useCallback(() => {
    const url = revenueServiceMappingPath(business.id, Number(revenueReportTypeId ?? 0));
    Backbone.history.navigate(url, { trigger: true });
    onDone();
  }, [business.id, onDone, revenueReportTypeId]);
  return (
    <Modal
      show={ isOpen }
      title="Revenue System Connected!"
    >
      <Modal.Body>
        <div className={ styles['bold-text'] }>
          <strong>
            You have successfully connected
            {' '}
            {UpsertIntegrationResponse?.externalSystemName}
            {' '}
            revenue system.
            {' '}
          </strong>
        </div>

        <div className={ styles['bottom-text-pull-integration'] }>
          Next step is to map the report line items with the category, department and the journal entry. It will be saved as revenue report type after the mapping.
        </div>
      </Modal.Body>
      <Modal.Footer className="modal-footer-v2">
        <Button
          variant="link"
          onClick={ onCancel }
        >
          Do it Later
        </Button>
        {featureQuery.data && (
          <Button
            type="submit"
            variant="primary"
            onClick={ handleOnClick }
          >
            Map Now
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

const usePullIntegrationSuccessModal = makeUseModal(PullIntegrationSuccessModal);

export {
  usePullIntegrationSuccessModal,
  PullIntegrationSuccessModal as default,
};
