import React, { useCallback, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useFieldArray, useForm } from 'react-hook-form';

import toastr from '@lib/toastr';
import { useCompleteOnboarding, useCreateTeamMember } from '@src/hooks/queries/signup';
import { ICreateTeamMemberParams } from '@src/requests/signup';

import { Button } from '@src/components/ui_v2/buttons';
import { SpinnerIcon } from '@src/components/utils/fa_icons';
import { PlusIcon } from '@src/components/utils/icomoon';

import TeamMemberFormItem from './form_item';
import { ONBOARDING_STATUS_MODES } from '../constant';
import OnboardingForm from '../layout/onboarding_form';
import { ITeamMemberFormValues, teamMemberValidation } from '../schema';

import styles from '../styles.module.scss';

interface ITeamMemberFormProps {
  formId: string;
}

const TeamMemberForm = ({ formId }: ITeamMemberFormProps) => {
  const businessId = window.Docyt.currentAdvisor.get(
    'default_accounting_firm',
  )?.id;

  const { mutateAsync } = useCreateTeamMember();
  const { mutate: completeOnboarding } = useCompleteOnboarding();

  const [showProgress, setShowProgress] = useState<boolean>(false);
  const [doneCount, setDoneCount] = useState<number>(0);
  const [isSubmit, setIsSubmit] = useState<boolean>(false);

  const {
    formState: { errors },
    control,
    register,
    handleSubmit,
  } = useForm<ITeamMemberFormValues>({
    defaultValues: {
      members: [
        {
          name:  '',
          email: '',
        },
      ],
    },
    resolver: yupResolver(teamMemberValidation),
  });

  const { fields, append, remove } = useFieldArray<ITeamMemberFormValues>({
    control,
    name: 'members',
  });

  const onSubmit = useCallback(
    async (data: ITeamMemberFormValues) => {
      const { members } = data;

      try {
        setIsSubmit(true);
        setShowProgress(true);

        const updatePromises = members.map((member) => {
          const params: ICreateTeamMemberParams = {
            employee: {
              businessIds:  [businessId],
              type:         'Employee',
              name:         member.name,
              email:        member.email,
              ignoreMatch:  false,
              detailsUrl:   '',
              documentsUrl: '',
            },
          };

          return mutateAsync(params).then(() => {
            setDoneCount((count) => count + 1);
          });
        });

        await Promise.all(updatePromises);
      } catch (error) {
        const errorMessage = (error as Error)?.message || 'An unknown error occurred';
        toastr.error(`Failed to create: ${errorMessage}`, 'Error');
      } finally {
        completeOnboarding(
          {},
          {
            onSuccess: () => {
              setIsSubmit(false);
              setDoneCount(0);
              setShowProgress(false);
              window.Docyt.currentAdvisor.get('user_on_boarding_status').mode = ONBOARDING_STATUS_MODES.ONBOARDING_STATUS_READY;
              Backbone.history.navigate('/', { trigger: true, replace: true });
            },
          },
        );
      }
    },
    [businessId, mutateAsync, setDoneCount, setIsSubmit, setShowProgress, completeOnboarding],
  );

  return (
    <OnboardingForm
      backButtonLabel=""
      className={ styles['team-members-form'] }
      formId={ formId }
      handleSubmit={ handleSubmit }
      icon={ <SpinnerIcon spin /> }
      isSubmit={ isSubmit }
      nextButtonLabel="Next"
      onSubmit={ onSubmit }
    >

      <div className="p-b-20 p-l-10 p-r-10 font-14">
        Invite your team to collaborate effortlessly. Enter their names and email addresses to grant
        <br />
        them access to your workspace.
      </div>
      <div className={ styles['team-members-form-wrapper'] }>
        <div className={ styles['team-members-form-header'] }>
          <div className={ styles['team-members-form-header-item'] }>
            Name
          </div>
          <div className={ styles['team-members-form-header-item'] }>
            Email Address
          </div>
          <div className={ styles['team-members-form-header-item'] } />
        </div>
        <div className="p-t-10 p-b-10">
          { fields.map((field, index) => (
            <TeamMemberFormItem
              key={ field.id }
              errors={ errors }
              fields={ fields }
              index={ index }
              register={ register }
              remove={ remove }
            />
          )) }
        </div>
        { showProgress && (
          <div className={ styles['progress-container'] }>
            <div
              className={ styles['progress-bar'] }
              style={ { width: `${Math.floor((doneCount * 100) / fields.length)}%` } }
            />
          </div>
        ) }
        <div className="p-l-10">
          <Button
            prefixIcon={ <PlusIcon fontSize={ 14 } /> }
            variant="link"
            onClick={ () => append({ name: '', email: '' }) }
          >
            Add Another Member
          </Button>
        </div>
      </div>
    </OnboardingForm>
  );
};

export default React.memo(TeamMemberForm);
