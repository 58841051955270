import React, { useState, useCallback } from 'react';

import { TextInput } from '@src/components/ui_v2/inputs';

import styles from './styles.module.scss';

const TaskNotes = ({ value }: { value: string }) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [currentValue, setCurrentValue] = useState<string>(value);

  const saveChanges = useCallback(() => {
    if (currentValue.trim() !== value.trim()) {
      // Api Code will appear here
    }
    setIsEditing(false);
  }, [currentValue, value]);

  const handleInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentValue(e.target.value);
  }, []);

  const handleBlur = useCallback(() => {
    saveChanges();
  }, [saveChanges]);

  const handleEditClick = useCallback(() => {
    setIsEditing(true);
  }, []);

  return (
    <div
      style={ { cursor: isEditing ? 'text' : 'pointer' } }
      onClick={ isEditing ? undefined : handleEditClick }
    >
      {isEditing ? (
        <TextInput
          autoFocus
          hideClear
          className={ styles['task-notes-input'] }
          value={ currentValue }
          onBlur={ handleBlur }
          onChange={ handleInputChange }
        />
      ) : (
        <span>{currentValue ?? ''}</span>
      )}
    </div>
  );
};

export default TaskNotes;
