import React from 'react';

import Icomoon, { IIcomoonIconProps } from './icomoon';

import styles from './styles.module.scss';

interface IExternalSystemsIconProps extends IIcomoonIconProps {
  type: string;
}
const ExternalSystemsIcon = ({
  type,
  ...props
}: IExternalSystemsIconProps) => {
  const getIcon: Record<string, { icon: string; length: number }> = {
    'anand-systems':                           { icon: 'anand-systems', length: 137 },
    'choice-advantage-reporting-system':       { icon: 'choice-advantage-reporting-system', length: 3 },
    'clover':                                  { icon: 'clover', length: 0 },
    'hotel-key':                               { icon: 'hotel-key', length: 14 },
    "flexe-pos-for-jersey-mike's":             { icon: 'flexepos-for-jersey-mikes', length: 7 },
    'lightspeed':                              { icon: 'lightspeed', length: 0 },
    'mews':                                    { icon: 'mews', length: 5 },
    'oracle-opera':                            { icon: 'oracle-opera', length: 11 },
    'oracle-opera-5-(desktop)':                { icon: 'oracle-opera', length: 11 },
    'paychex':                                 { icon: 'paychex', length: 11 },
    'paychex---multi-entity-parent-framework': { icon: 'paychex', length: 11 },
    'roomkey':                                 { icon: 'roomkey', length: 3 },
    'shopify':                                 { icon: 'shopify', length: 3 },
    'square':                                  { icon: 'square', length: 0 },
    'str':                                     { icon: 'str', length: 0 },
    'stripe':                                  { icon: 'stripe', length: 2 },
    'synxis':                                  { icon: 'synxis', length: 15 },
    'ups-store':                               { icon: 'ups-store', length: 6 },
    'visual-matrix-reporting-system':          { icon: 'visual-matrix-reporting-system', length: 18 },
  };
  const iconData = getIcon[type];
  if (!iconData) {
    return <div className={ styles['revenue-system-indicator'] } />;
  }
  return (
    <Icomoon
      className={ styles['external-systems-icon'] }
      { ...props }
      iconName={ iconData.icon }
      { ...(iconData.length > 0 && { pathsNumber: iconData.length }) }
    />
  );
};
export default ExternalSystemsIcon;
