import { TID } from '@src/types/common';
import { AuditScan } from '@src/types/operational_dashboard/audit_scan'; // Adjust the import path as necessary
import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

import { apiGet, apiPost, apiPut } from '../helpers';

interface IGetLatestAuditScansParams {
  businessId?: TID;
}

interface IGetLatestAuditScansResponse {
  auditScans: AuditScan[];
}

interface IRunAuditScanParams {
  businessId: TID;
}

interface ICloseAuditScanParams {
  id: TID;
  closeDate: string;
  notes: string;
  businessId: TID;
}

const getLatestAuditScans = (params: IGetLatestAuditScansParams): Promise<AuditScan> => {
  return apiGet('/api/v1/audit_scans/latest', { business_id: params.businessId })
    .then((data) => {
      const response = <IGetLatestAuditScansResponse>camelizeKeys(data);
      return response.auditScans[0] as AuditScan;
    });
};

const runAuditScan = (params: IRunAuditScanParams):Promise<{}> => {
  return apiPost(
    '/api/v1/audit_scans',
    underscoreKeys({ business_id: params.businessId }),
  ).then((data) => {
    const response = camelizeKeys(data) as AuditScan;
    return response;
  });
};

const closeAuditScan = (params: ICloseAuditScanParams): Promise<void> => {
  return apiPut(`/api/v1/audit_scans/${params.id}/close`, {
    close_date:  params.closeDate,
    note:        params.notes,
    business_id: params.businessId,
  }).then(() => {
  })
    .catch((error) => {
      throw error;
    });
};

export {
  getLatestAuditScans,
  runAuditScan,
  IGetLatestAuditScansParams,
  IGetLatestAuditScansResponse,
  IRunAuditScanParams,
  closeAuditScan,
};
