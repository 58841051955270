import React from 'react';

import { aiChatBotPath } from '@src/routes';

import { Button } from '@src/components/ui_v2/buttons';
import { DocytBotAi } from '@src/components/utils/icomoon';

import styles from '../styles.module.scss';

const NavigateBtn = () => {
  return (
    <div className={ styles['navigate-btn'] }>
      <Button
        className={ styles.logo }
        variant="link"
        onClick={ () => {
          window.localStorage.removeItem('default_question');
          Backbone.history.navigate(aiChatBotPath(), { trigger: true });
        } }
      >
        <DocytBotAi fontSize={ 22 } />
        <span className={ styles['logo-text'] }>
          Ask Docyt AI
        </span>
      </Button>
    </div>
  );
};

export default React.memo(NavigateBtn);
