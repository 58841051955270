import React, { memo, useEffect } from 'react';

import { Outlet } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { isNavBarVisibleAtom } from '@src/components/ai_chatbot/atoms';

import NavBar from './components/nav_bar';
import useSendbirdHelper from './hooks/use_sendbird_helper';

import styles from './styles.module.scss';

const AiChatBotMainView = () => {
  const { sendbirdInstance } = useSendbirdHelper();
  const isNavBarVisible = useRecoilValue(isNavBarVisibleAtom);

  useEffect(() => {
    return () => {
      if (sendbirdInstance) {
        sendbirdInstance.removeAllChannelHandlers();
      }
    };
  }, [sendbirdInstance]);

  return (
    <div className={ styles['chat-container'] }>
      {!isNavBarVisible
        && (
        <div className={ styles['chat-container-header'] }>
          <NavBar />
        </div>
        )}
      <Outlet />
    </div>
  );
};

export default memo(AiChatBotMainView);
