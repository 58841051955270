import React from 'react';

import classNames from 'classnames';

import { ITaskServiceDocument } from '@src/types/task_service_documents';
import { getDateGap } from '@src/utils/date_helpers';

import Assignee from '@src/components/task_management/components/assignee';
import TaskOpenSince from '@src/components/task_management/components/task_open_since';
import TaskStatusSelect from '@src/components/task_management/components/task_status_select';
import { StatusKey } from '@src/components/task_management/types';
import Table from '@src/components/ui_v2/table';

import styles from '../styles.module.scss';

interface IDashboardTableBodyProps {
    records:ITaskServiceDocument[];
    onRowClick : (rowData : ITaskServiceDocument) => void;
}
const TableBody = ({ records, onRowClick }:IDashboardTableBodyProps) => {
  const classes = classNames(styles['task-default-row'], styles['red-row']);
  return (
    <Table.Body>
      {records?.map((columnData:ITaskServiceDocument) => (
        <Table.Row
          key={ columnData.taskId }
          className={ getDateGap(columnData.createdAt).isGreaterOrEqualTwoDays
            ? classes : styles['task-default-row'] }
        >
          <Table.Cell onClick={ () => onRowClick(columnData) }>
            <div className={ styles['row-text-values'] }>
              #
              {columnData.taskId}
            </div>
          </Table.Cell>
          <Table.Cell onClick={ () => onRowClick(columnData) }>
            <div className={ styles['row-text-values'] }>
              {columnData?.title}
            </div>
          </Table.Cell>
          <Table.Cell onClick={ () => onRowClick(columnData) }>
            <div className={ styles['row-text-values'] }>
              {columnData?.businessName}
            </div>
          </Table.Cell>
          <Table.Cell>
            <Assignee businessId={ 1399 } />
          </Table.Cell>
          <Table.Cell>
            <TaskStatusSelect selectedValue={ columnData.status as StatusKey } />
          </Table.Cell>
          <Table.Cell onClick={ () => onRowClick(columnData) }>
            <div className={ styles['row-text-values'] }>
              <TaskOpenSince date={ columnData?.createdAt } />
            </div>
          </Table.Cell>
        </Table.Row>
      ))}
    </Table.Body>
  );
};
export default TableBody;
