import React from 'react';

import styles from './document_list.module.scss';

interface NoStatementCheckboxProps {
  isMailroomRequest: boolean;
  documentsLength: number;
  noStatementAvailable: boolean;
  setNoStatementAvailable: (value: boolean) => void;
}

const NoStatementCheckbox: React.FC<NoStatementCheckboxProps> = ({
  isMailroomRequest,
  documentsLength,
  noStatementAvailable,
  setNoStatementAvailable,
}) => {
  // Only show for non-mailroom requests AND when no documents exist
  if (isMailroomRequest || documentsLength > 0) {
    return null;
  }

  return (
    <div className={ styles['no-statement-checkbox'] }>
      <label className={ styles['no-statement-checkbox-label'] }>
        <input
          checked={ noStatementAvailable }
          type="checkbox"
          onChange={ (e) => setNoStatementAvailable(e.target.checked) }
        />
        <span>No Statement Available</span>
      </label>
    </div>
  );
};

export default NoStatementCheckbox;
