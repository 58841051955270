import React from 'react';

import { useGetTaskServiceDocumentById } from '@src/hooks/queries/task_service_documents';
import { ITaskServiceDocument } from '@src/types/task_service_documents';

import Spinner from '@src/components/ui/spinner';

import DetailsSection from './details_section';
import TasksLogs from './tasks_logs';

import styles from '../styles.module.scss'; // Import SCSS styles with kebab-case naming

const TaskDetails = ({ taskId }:{taskId:string}) => {
  const { data, isLoading, error } = useGetTaskServiceDocumentById(taskId);
  if (isLoading) {
    return <Spinner />;
  }
  const handleBack = () => {
    Backbone.history.history.back();
  };
  if (error) {
    handleBack();
    return null;
  }
  return (
    <div className={ styles['task-container'] }>
      <div className={ styles['task-content'] }>
        <DetailsSection taskServiceDocument={ data as ITaskServiceDocument } onGoBack={ handleBack } />
        <TasksLogs taskId={ taskId } />
      </div>
    </div>
  );
};
export default TaskDetails;
