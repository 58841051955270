import React, { useCallback, useState } from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { useLDBusinessFeatureQuery } from '@src/hooks/queries/launch_darkly_features';
import {
  IUpsertIntegrationResponse,
} from '@src/requests/integrations/integrations';
import { revenueServiceMappingPath } from '@src/routes';
import { TID } from '@src/types/common';

import Modal from '@src/components/ui/modal';
import Text from '@src/components/ui/text';
import { Button } from '@src/components/ui_v2/buttons';
import { CheckGreenIcon, ContentCopyIcon } from '@src/components/utils/icomoon';

import styles from './styles.module.scss';

interface IpushIntegrationSuccessModalProps extends IUseModalProps {
  UpsertIntegrationResponse?: IUpsertIntegrationResponse | null,
  revenueReportTypeId?: TID | null | undefined,
}

const PushIntegrationSuccessModal = ({
  isOpen,
  onDone,
  onCancel,
  UpsertIntegrationResponse,
  revenueReportTypeId,
}: IpushIntegrationSuccessModalProps) => {
  const business = useBusinessContext();

  const [badgeShow, setBadgeShow] = useState(false);

  const featureQuery = useLDBusinessFeatureQuery(
    business.id,
    window.Docyt.Common.Constants.REVENUE_CENTER_NEW_REVENUE_MAPPING,
  );
  const copyEmail = useCallback(() => {
    navigator.clipboard.writeText(UpsertIntegrationResponse?.emailAddress || '');
    setBadgeShow(true);
  }, [UpsertIntegrationResponse]);

  const handleOnClick = useCallback(() => {
    const url = revenueServiceMappingPath(business.id, Number(revenueReportTypeId ?? 0));
    Backbone.history.navigate(url, { trigger: true });
    setBadgeShow(false);
    onDone();
  }, [business.id, onDone, revenueReportTypeId]);
  return (
    <Modal
      show={ isOpen }
      title="Revenue System Connected!"
    >
      <Modal.Body>
        <div className={ styles['bold-text'] }>
          <strong>
            You have successfully connected
            {' '}
            {UpsertIntegrationResponse?.externalSystemName}
            {' '}
            revenue system.
            {' '}
          </strong>
        </div>
        <div className={ styles['second-text'] }>Use the below email address in your revenue system to send daily revenue reports to Docyt.</div>
        <div className={ styles['mixed-line-text'] }>
          <Text fontSize={ 12 } inColor="black">
            <Text className={ styles['email-text'] } fontVariant="semi-bold">
              {UpsertIntegrationResponse?.emailAddress}
            </Text>
          </Text>
          <Button
            variant="link"
            onClick={ copyEmail }
          >
            <ContentCopyIcon fontSize={ 16 } ml={ 10 } />
          </Button>
          {badgeShow ? (
            <Text className={ styles['badge-copy'] } fontSize={ 12 }>
              <CheckGreenIcon fontSize={ 17 } />
              Copied to Clipboard
            </Text>
          ) : null}
        </div>
        <div className={ styles['bottom-text'] }>Next step is to map the report line items with the category, department and the journal entry. It will be saved as revenue report type after the mapping.</div>
      </Modal.Body>
      <Modal.Footer className="modal-footer-v2">
        <Button
          variant="link"
          onClick={ onCancel }
        >
          Do it Later
        </Button>
        {featureQuery.data && (
          <Button
            type="submit"
            variant="primary"
            onClick={ handleOnClick }
          >
            Map Now
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

const usePushIntegrationSuccessModel = makeUseModal(PushIntegrationSuccessModal);

export {
  usePushIntegrationSuccessModel,
  PushIntegrationSuccessModal as default,
};
