import { IBillingPaymentMethodStatus } from '@src/types/billing_payment_methods';
import { IBusiness } from '@src/types/businesses';
import { TID } from '@src/types/common';
import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

import { apiGet, apiPost, apiPut, notAuthorizedApiRequest } from './helpers';

interface ICreateUserAccountParams {
  user: {
    name: string;
    password: string;
    passwordConfirmation: string;
    onboardingType: string;
  };
  emailAccessToken: string;
}

interface ISignInParams {
  email: string;
  password: string;
  clientId: string;
  clientSecret: string;
  deviceUuid: string | null;
  deviceName: string;
  grantType: string;
}

interface ISignInResponse {
  accessToken: string;
  refreshToken: string;
}

interface ICreateTeamMemberParams {
  employee: {
    businessIds: number[];
    type: string;
    name: string;
    email: string;
    ignoreMatch: boolean;
    detailsUrl: string;
    documentsUrl: string;
  };
}

interface ISetBusinessInfoParams {
  setupConfig: any;
  businessId: TID;
}

const validateEmail = (email: string): Promise<{ success: boolean }> => {
  return notAuthorizedApiRequest({
    method: 'GET',
    url: '/api/v1/users/validate_email',
    params: underscoreKeys({ email }),
  }).then((data) => camelizeKeys(data) as { success: boolean });
};

const verifyEmail = (
  email: string
): Promise<{ success: boolean; emailAccessToken: string }> => {
  return notAuthorizedApiRequest({
    method: 'POST',
    url: '/api/v1/email_verifications',
    data: underscoreKeys({ email }),
  }).then(
    (data) =>
      camelizeKeys(data) as { success: boolean; emailAccessToken: string }
  );
};

const confirmEmail = (
  emailAccessToken: string,
  accessCode: string
): Promise<{ success: boolean }> => {
  return notAuthorizedApiRequest({
    method: 'POST',
    url: '/api/v1/email_verifications/confirm_email',
    data: underscoreKeys({ emailAccessToken, accessCode }),
  }).then((data) => camelizeKeys(data) as { success: boolean });
};

const resendEmail = (emailAccessToken: string): Promise<void> => {
  return notAuthorizedApiRequest({
    method: 'POST',
    url: '/api/v1/email_verifications/resend_email',
    data: underscoreKeys({ emailAccessToken }),
  });
};

const createUserAccount = (params: ICreateUserAccountParams): Promise<void> => {
  return notAuthorizedApiRequest({
    method: 'POST',
    url: '/api/v1/users',
    data: underscoreKeys(params),
  });
};

const signIn = (params: ISignInParams): Promise<ISignInResponse> => {
  return notAuthorizedApiRequest({
    method: 'POST',
    url: '/auth/api/v1/oauth/token',
    data: underscoreKeys(params),
  }).then((data) => camelizeKeys(data) as ISignInResponse);
};

const getCurrentTenant = (): Promise<{ logUrl: string }> => {
  return apiGet('/api/v1/tenants/current').then(
    (data) => camelizeKeys(data.tenant) as { logUrl: string }
  );
};

const getCurrentAdvisor = (): Promise<any> => {
  return apiGet('/api/web/v1/advisor/current_advisor').then(
    (data) => data.advisor
  );
};

const getDefaultAccountingFirm = (): Promise<IBusiness> => {
  return apiGet('/api/v1/businesses/default_accounting_firm.json').then(
    (data) => camelizeKeys(data.business) as IBusiness
  );
};

const getBillingPaymentMethodStatus = (params: {
  businessId: TID | null;
}): Promise<IBillingPaymentMethodStatus> => {
  return apiGet(
    '/billing/api/v1/business_billings/has_payment_method',
    underscoreKeys(params)
  ).then((data) => camelizeKeys(data) as IBillingPaymentMethodStatus);
};

const createTeamMember = (params: ICreateTeamMemberParams): Promise<void> => {
  return apiPost('/api/web/v1/employees', underscoreKeys(params));
};

const completeOnboarding = (): Promise<void> => {
  return apiPut('/api/v1/onboarding_status/complete_onboarding', {});
};

const getQboCustomers = (params: { page: TID; businessId: TID }): Promise<any> => {
  return apiGet(
    `/api/v1/businesses/${params.businessId}/fetch_quickbooks_customers`,
    underscoreKeys({ page: params.page })
  ).then((data) => camelizeKeys(data) as any);
}

const addBillingMethod = (params: {
  businessId: TID | null;
  returnUrl: string | null;
}): Promise<{ url: string }> => {
  return apiGet(
    '/billing/api/v1/business_billings/billing_portal_session',
    underscoreKeys(params)
  ).then((data) => camelizeKeys(data) as { url: string });
};

const setBisinssInfo = (params: ISetBusinessInfoParams): Promise<void> => {
  return apiPut(
    `/api/v1/businesses/${params.businessId}`,
    underscoreKeys({ setupConfig: params.setupConfig })
  );
};

export {
  ICreateUserAccountParams,
  ICreateTeamMemberParams,
  ISignInParams,
  ISignInResponse,
  ISetBusinessInfoParams,
  validateEmail,
  verifyEmail,
  confirmEmail,
  resendEmail,
  createUserAccount,
  signIn,
  getCurrentTenant,
  getCurrentAdvisor,
  getDefaultAccountingFirm,
  createTeamMember,
  completeOnboarding,
  getQboCustomers,
  addBillingMethod,
  setBisinssInfo,
  getBillingPaymentMethodStatus,
};
