import { TMongoID } from '@src/types/common';
import { IReportItem } from '@src/types/report_service/report_item';
import { IReportServiceQBOLineItemDetails } from '@src/types/report_service/report_item_value_account';
import { getReportBTFEndPoint } from '@src/utils/config';
import { camelizeKeys } from '@src/utils/transform_keys';

import { apiGet } from '../helpers';

const getReportItems = (reportId: TMongoID, endPoint: string): Promise<IReportItem[]> => {
  const finalEndPoint = endPoint || getReportBTFEndPoint(window.Docyt.Common.Constants.BTF_SERVICE_API_LIST_KEYS.REPORT_ITEMS);
  return apiGet(
    `/${finalEndPoint}/api/v1/reports/${reportId}/items`,
  ).then((data) => camelizeKeys(data.items) as IReportItem[]);
};

const getReportByIdentifier = (reportId: string, reportIdentifier: string): Promise<IReportItem> => {
  return apiGet(
    `/reports/api/v1/reports/${reportId}/items/by_identifier?item_identifier=${reportIdentifier}`,
  ).then((data) => camelizeKeys(data.item) as IReportItem);
};

const getItemLineItemDetails = (reportId: string, reportDataId: string, slug: string, itemId: string, columnId: string, chartOfAccountId: number, page: number): Promise<IReportServiceQBOLineItemDetails[]> => {
  let endPoint = '';
  if (slug === window.Docyt.Common.Constants.BUSINESS_REPORT_TYPE.PROFIT_AND_LOSS_REPORT_TYPE || slug === window.Docyt.Common.Constants.BUSINESS_REPORT_TYPE.BALANCE_SHEET_REPORT_TYPE) {
    endPoint = 'report-dc';
  } else {
    endPoint = 'reports';
  }
  return apiGet(
    `/${endPoint}/api/v1/report_datas/${reportDataId}/item_account_values/line_item_details?item_id=${itemId}&column_id=${columnId}&chart_of_account_id=${chartOfAccountId}&page=${page}&report_id=${reportId}`,
  ).then((data) => camelizeKeys(data.line_item_details) as IReportServiceQBOLineItemDetails[]);
};

export {
  getReportItems,
  getReportByIdentifier,
  getItemLineItemDetails,
};
